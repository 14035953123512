import { Component, EventEmitter, Input, OnInit, Output, HostListener, AfterViewInit } from '@angular/core';
import { CommunicationService } from "../services/communication.service";
import { ControllerService } from "../services/controller.service";
import { TagService } from '../services/tag.service';
import { ConfigService } from '../services/config.service';
import { DrawService } from '../services/draw.service';
import { SocketService } from '../services/socketService';
import { ChitService } from '../services/chit.service';
import { DatePipe } from '@angular/common';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileValidators } from "ngx-file-drag-drop";
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { TagoutService } from '../services/tagout.service';

@Component({
  selector: 'app-tag-point',
  templateUrl: './tag-point.component.html',
  styleUrls: ['./tag-point.component.scss'],
  providers: [DatePipe,
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
    }]
})
export class TagPointComponent implements OnInit {
  @Input() pickedMeshObj: any;
  filterDate: any = new Date("01-MAR-2021");
  broadcastSubs: any;
  groupArray = [];
  statusArray = [];
  saveNotesData: any = {};
  showTypeData: any;
  chitWindowState: boolean = false;
  tagoutWindowState: boolean = false;
  scheduleWindowState: boolean = false;
  showTagPoint: any;
  showNotificationTagPoint: any;
  selectedTagItem: any;
  commetsWindow: boolean = false;
  layoutData: any = [];
  cfbport: string = 'cfbesquimalt';
  markerId: any;
  layoutId: any;
  selectedLayoutList: any = []
  LayoutMode: any = false;
  layoutName: any;
  layoutHeading: any = "";
  statusListData: any = [];
  groupList: any = [];
  temporaryCommentData: any;
  saveLayoutData: any = {};
  usersDetails: any = [];
  progressStatus = true
  typeArray = [
    { name: 'Rounds' },
    { name: 'Chits' },
    { name: 'Lockout/Tagout' },
    { name: 'Aid' },
    { name: 'HazardAreas' },
    { name: 'Kiosks' },
    { name: 'LaydownAreas' },
    { name: 'Turnover' },
    { name: 'Defects' },
    { name: 'Flex' },
    { name: 'Visitor' },
    { name: 'logBook' },
    { name: 'Hot Workings' },
    { name: 'Teams' }
  ];
  shapecollectionArray = [
    { name: 'Aid', icon: '../../assets/images/shape/aed.png', id: 'aed_pin_red' },
    // {name: 'Contractors', icon: '../../assets/images/shape/contractors.png',id : 'sphere'},
    { name: 'Hazard Areas', icon: '../../assets/images/shape/hazard.png', id: 'cone_m' },
    { name: 'Kiosks', icon: '../../assets/images/shape/kiosk.png', id: 'kiosk_SM_01_mech' },
    { name: 'Evacuation posters', icon: '../../assets/images/shape/evacuation-posters.png', id: 'location_pin' },
    // {name: 'First aid stations', icon: '../../assets/images/shape/first-aid.png',id : 'cube'},
    { name: 'Activity', icon: '../../assets/images/shape/cylinder.png', id: 'cylinder' },
  ];
  statusHeading: string;
  btnStatus: string;
  statusColor: any;
  statusId: any;
  statusName: string;
  statusWindow: boolean = false;
  groupWindow: boolean = false;
  userRole: any;
  loginInfo: any = {};
  stateBoardForm: boolean = false
  adminStateBoardView: boolean = true;
  mobileView: any = false;
  stateBoardView: boolean = false;
  roundsApprove: boolean = false;
  chitsApprove: boolean = false;
  scheduleApprove: boolean = false;
  notesData: any;
  status: any;
  roundsId: any;
  roundsNotes: any;
  commentsId: any;
  locationShape: any;
  locationShapeModel: boolean = false;
  locationShapeCollectionIcon: any;
  locationShapeCollectionImg: any;
  LocationShapeArray = [
    { name: 'Aid', icon: '../../assets/images/shape/aed.png', id: 'aed_pin_red' },
    // {name: 'Contractors', icon: '../../assets/images/shape/contractors.png',id : 'sphere'},
    { name: 'Hazard Areas', icon: '../../assets/images/shape/hazard.png', id: 'cone_m' },
    // { name: 'Kiosks', icon: '../../assets/images/shape/kiosk.png', id: 'kiosk_SM_01_mech' },
    { name: 'Evacuation posters', icon: '../../assets/images/shape/evacuation-posters.png', id: 'location_pin' },
    // {name: 'First aid stations', icon: '../../assets/images/shape/first-aid.png',id : 'cube'},
    { name: 'Activity', icon: '../../assets/images/shape/cylinder.png', id: 'cylinder' },
    { name: 'Rounds', icon: '../../assets/images/shape/avatar1.png', id: 'avatar1' },
    { name: 'Visitors', icon: '../../assets/images/shape/avatar2.png', id: 'avatar2' },
    { name: 'Flex', icon: '../../assets/images/shape/avatar3.png', id: 'avatar3' },
    { name: 'Lockout/Tagout', icon: '../../assets/images/shape/avatar4.png', id: 'avatar4' },
  ];
  roundsData: any;
  uploadHeader: any;

  @Input() set loadedPort(v: any) {
    this.cfbport = v;
  }
  visitorIdx = 0;
  visitorInformation: any;

  //****Location Master */
  locationFormGroup!: FormGroup;
  roundstype: any = ['Small Arms', 'Safety & Security', 'Habitability', 'MCR Technical Rounds', 'Departmental Rounds', 'Magazine Rounds', 'Fire Prevention']
  Locations = ["Gate In", "Gate Out", "Deck 1", "Deck 2", "Deck 3", "Deck 4"];
  flexType: any = ['Maintenance', 'Ventilation']
  lotoType = [
    {
      "key": "A1",
      "value": "A1"
    },
    {
      "key": "A1,2",
      "value": "A1,2"
    },
    {
      "key": "A2",
      "value": "A2"
    },
    {
      "key": "A2,2",
      "value": "A2,2"
    }]
  locationFormButton: boolean = false;
  updatelocationFormButton: boolean = false;
  locationForm: boolean = false;
  locationListData: any = [
    { 'key': 'visitor', 'value': 'Visitor' },
    { 'key': 'rounds', 'value': 'Rounds' },
    { 'key': 'flex', 'value': 'Flex' },
    { 'key': 'loto', 'value': 'LOTO' }
  ]
  viewRoundsType: boolean = false;
  viewVisitorType: boolean = false;
  viewFlexType: boolean = false;
  viewlotoType: boolean = false;
  locationMaster:boolean = false;
  showerror: boolean = false;
  locationType: any;
  planUploadStatus: boolean = false;
  uploadPopupState: boolean = false;

  constructor(
    private communicationServ: CommunicationService,
    private configService: ConfigService,
    private drawService: DrawService,
    private tagServ: TagService,
    private socketService: SocketService,
    private ctrlServ: ControllerService,
    private chitService: ChitService, private datePipe: DatePipe,
    private _formBuilder: FormBuilder,
    private tagoutServ: TagoutService
  ) { 
    this.mobileView = window.innerWidth < 720; 
    this.locationFormGroup = this._formBuilder.group({
      _id: '',
      type: ['', Validators.required],
      meshId: [''],
      location: ['{"x":0,"y":0,"z":0}', Validators.required],
      locationType: ["", Validators.required],
      locationKey: [""],
      locationShape: [""]
    });
  }
  selectedCollection: any;
  turnoverCreateMsd: any;
  chitCreateDate: any;
  chitFormvalue: any;
  chitsStatus:any="inprogress";
  chitData:any;
  selectUser :any;
  selectNotesData:any;
  broadcastIframeSubs: any;
  selectedLayoutCollection: any = {};
  logbookLayoutCollection: any = {};

  uploadFile =new FormControl(
    [],
    [FileValidators.required,
    FileValidators.maxFileCount(1)]
  );
  ngOnInit(): void {
    this.getUserData();
    console.log("pickedMeshObj", this.pickedMeshObj);
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === 'stepper-form') {
          if (data.event == 'createMultipleVisitor') {
            this.visitorInformation = data.visitorInformation;
            this.chitCreateDate = data.chitCreateDate;
            this.chitFormvalue = data.formValue;
            this.selectedTagItem = data.selectedTagItem;
            this.visitorIdx = 0;
            this.createVisitorNotes();
          }
        }

        if (data.src === "sidepanel") {
          if (data.event == "reconstruct") {
            this.selectNotesData = data['notes'];
            console.log("tagpoint-reconstruct",this.selectNotesData)
          }
          if (data.event == "turnoverNotifyPopup") {
            this.turnoverCreateMsd = data['data'];
          }
          if (data.event == "chitsApproveStatus") {
            delete this.chitsNotes;
            this.chitsApprove = true;
            delete  this.chitData;
            delete this.selectUser;
            delete this.userEmailData;
            this.chitsStatus="inprogress";
            this.chitData =data['chit'];
            this.selectUser = data['data'];
            this.userEmailData = data['chitEmailList'];
          }
          if (data.event == "roundsApproveStatus") {
            this.roundsApprove = true;
            let rounds = data['data'];
            if (rounds) {
              this.chitService.getRoundsData(rounds.typeCollectionId).subscribe(
                (response) => {
                  var repos = response;
                  console.log("chit data--- : ", repos[0]);
                  this.status = repos[0].status;
                  this.roundsId = rounds.typeCollectionId;
                  this.roundsNotes = repos[0].notes;
                },
                error => {
                  console.error('Request failed with error')
                })
              this.configService.getNotesView(rounds._id).then(response => {
                console.log('getNotesDataView', response);
                var repos = response;
                // console.log("chit data--- : ", repos[0]); 
                if (repos) {
                  this.notesData = repos[0];
                }
              })
            }
          }
          if (data.event == "scheduleApproveStatus") {
            this.scheduleApprove = true;
            let rounds = data['data'];
            if (rounds) {
              this.chitService.getScheduleDatas(rounds.typeCollectionId).subscribe(
                (response) => {
                  var repos = response;
                  console.log("chit data--- : ", repos[0]);
                  this.status = repos[0].status;
                  this.roundsId = rounds.typeCollectionId;
                  this.roundsNotes = repos[0].notes;
                },
                error => {
                  console.error('Request failed with error')
                })
              this.configService.getNotesView(rounds._id).then(response => {
                console.log('getNotesDataView', response);
                var repos = response;
                // console.log("chit data--- : ", repos[0]); 
                if (repos) {
                  this.notesData = repos[0];
                }
              })
            }
          }
          if (data.event == "roundsApproveFalse") {
            this.roundsApprove = false;
          }
          if (data.event == "stateBoardEnable") {
            this.stateBoardView = false;
            this.ctrlServ.stateBoardView = this.stateBoardView;
            this.adminStateBoardView = data['data'];
          }
          if (data.event === 'updateCollectionData') {
            let collectionData = data['data'];
            if (collectionData) {
              this.statusArray = collectionData.statusCollection ? collectionData.statusCollection : [];
              this.groupArray = collectionData.groupCollection ? collectionData.groupCollection : [];
            }
          }
          if (data.event == "layoutActive") {
            if (data.data.length == 0 && !this.selectedLayoutList) {
              this.LayoutMode = false;
              this.selectedLayoutList = data['data'];
              this.layoutName = ''
              this.layoutHeading = ''
            } else {
              this.LayoutMode = true;
              this.selectedLayoutList = data['data'];
              this.showTypeData = this.selectedLayoutList['type']
              this.showTagPoint = data.tagShow
              console.log(this.showTagPoint)
              this.drawService.layoutData = this.selectedLayoutList;
              this.layoutHeading = this.selectedLayoutList['layoutName'];
              this.layoutName = this.selectedLayoutList['layoutName'];
              this.layoutId = this.selectedLayoutList['_id'];
              if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == "createCollectionFormActive" && this.chitService.typeCollectionForm['type'] == 'Chits') {
                this.broadcastInfo({ src: 'navyApp', event: 'createCollectionChit', data: this.layoutData, saveNotesData: this.saveNotesData, selectedLayoutList: this.selectedLayoutList });
                delete this.chitService.typeCollectionForm;
              }

              this.broadcastInfo({ src: 'tagPoint', event: 'tagShow', data: this.showTagPoint });
              this.broadcastInfo({ src: 'tagPoint', event: 'showTypeData', data: this.showTypeData, });
            }
          }
          if (data.event == "layoutUnselected") {
            this.statusArray = [];
            this.groupArray = [];
            this.selectedLayoutList = [];
          }
          if (data.event == "createLayout") {
            this.openLayout()
            delete this.layoutHeadingName;
            delete this.shapeCollection;
            delete this.groupCollection;
            delete this.statusCollection;
            delete this.typeCollection;
            delete this.saveLayoutData;
            delete this.pickedMeshObj
            this.groupList = data.group;
            this.statusListData = data.status;
          }
          if (data.event == "createLocation") {
            this.openLocationForm('create')
            delete this.layoutHeadingName;
            delete this.shapeCollection;
            delete this.groupCollection;
            delete this.statusCollection;
            delete this.typeCollection;
            delete this.saveLayoutData;
            delete this.pickedMeshObj
            this.groupList = data.group;
            this.statusListData = data.status;
          }
          if (data.event == "editLocation") {
            this.openLocationForm('edit');
            this.setLocationForm(data.location)
          }
          
          if (data.event === "openDialogLayoutEdit") {
            // console.log("openDialogLayoutEdit",data['data'],this.layoutData);
            this.editCollection = true;
            this.layoutModel = true;
            this.layoutHeadingName = data['data']['layoutName'];
            this.editCollectionData = data['data'];
            this.layoutData = data.key2;
          }
          if (data.event == "createStatus") {
            this.btnStatus = 'Create';
            this.statusHeading = 'Create Status';
            this.statusName = "";
            this.statusColor = this.getRandomColor();
            this.statusWindow = true;
          }
          if (data.event == "updateStatus") {
            console.log("update", data['data'])
            var statusData = data['data']
            this.statusName = statusData.name;
            this.statusColor = statusData.value;
            this.statusId = statusData._id;
            this.btnStatus = 'Update';
            this.statusHeading = 'Update Status';
            this.statusWindow = true;
          }
          if (data.event == "statusDelete") {
            let deleteGroupData = data.data
            var deleteId = deleteGroupData.item
            if (deleteId['_id'] != undefined) {
              this.statusId = deleteId['_id']
              this.deleteStatusData(this.statusId);
            }
          }
          if (data.event == "createGroup") {
            this.openGroupPanel("new");
          }
          if (data.event == "updateGroup") {
            console.log("update", data['data'])
            this.openGroupPanel("edit", data['data']);
          }
          if (data.event == "groupDelete") {
            let obj = data['data'];
            if (obj) {
              this.deleteGroup(obj);
            }
          }
          if (data.event == "selectedTagItem") {
            let obj = data['data'];
            if (obj) {
              this.selectedTagItem = obj;
            }
          }
          if (data.event == "seletSelectedTagItem") {
            delete this.selectedTagItem;
          }

          if(data.event == 'openFileUploadPopup'){
              this.uploadPopupState = data.status;
              this.uploadHeader = data.typeCollection;
              this.selectedLayoutCollection = data['selectedCollection'] || {};
              this.logbookLayoutCollection = data['logbookCollection'] || {};
          }
        }
        if (data.src === "tagPoint") {
          if (data.event === 'defaultColor') {
            this.saveNotesData = data['data'];
            this.selectedTagItem = data['key'];
          }
          if (data.event === 'showTypeData') {
            this.showTypeData = data['data'];
          }
          if (data.event === 'tagShow') {
            this.showTagPoint = data['data'];
            console.log(this.showTagPoint)
          }
          if (data.event === 'notificationTag') {
            this.showNotificationTagPoint = data['data'];
          }
        }
        if (data.src == 'navyApp') {

          if (data.event == 'layoutData') {
            this.layoutData = data.data;
          }
          if (data.event == 'updateStatus') {
            this.statusListData = data.data;
            this.groupList = data['key'];
          }
          if (data.event == 'updateGroup') {
            this.groupList = data.data;
          }
          if (data.event == 'userDetails') {
            this.usersDetails = data.data;
          }
          if (data.event == 'layoutPort') {
            this.cfbport = data.data;
          }
        }
        if (data.src == "comment") {
          if (data.event == "create-comment") {
            this.saveNotesData = data["data"]
            this.onSaveLayout()
          }
          if (data.event == "saveActivityMessage") {
            if (!this.socketService.notificationData) {
              this.socketService.notificationData = data.notificationData
            }
            if (data.meassage) {
              this.socketService.notificationData.messageData.comments = data.ApprovalStatus + "-" + data.meassage
            } else {
              this.socketService.notificationData.messageData.comments = data.ApprovalStatus
            }
            this.socketService.notificationData.messageData.Messagedate = new Date();
            this.socketService.notificationData.messageData.email = this.configService.accountEmail;
            var userData = this.usersDetails.filter(obj => obj.email == this.configService.accountEmail)
            this.socketService.notificationData.messageData.name = userData[0]['First Name'] + '' + userData[0]['Last Name'];
            this.configService.postData(this.configService.project_config.activityMessage, this.socketService.notificationData.messageData).subscribe(result => {
            })
          }
          if (data.event == "update-comment") {
            this.updateComment(data)
          }
          if (data.event == "update-comment-data") {
            this.updateDescription(data)
          }
          if (data.event == 'notesDelete') {
            console.log(data.data)
          }
          if (data.event == 'temporary-comment') {
            this.temporaryCommentData = data['data']
          }
          if (data.event == 'commentsData') {
            var comments = data['data'];
            if (comments != undefined) {
              this.saveNotesData = comments;
              this.saveNotesData['tagShapeId'] = this.pickedMeshObj.tagShapeId
              if (!this.layoutHeading) {
                this.saveLayoutData();
              }
            }
          }
        }
        if (data.src == 'turnoverSheet') {
          if (data.event == 'updateNotes') {
            this.updateNotes(data['data']);
          }
          if (data.event == "turnoverUpdateNotes") {
            this.updateNotes(data['data']);
          }
        }

        if (data.src == 'logout/tagout') {
          if (data.event == 'updateNotes') {
            this.updateNotes(data['data']);
          }
        }
        


        //*** CREATE COMMENTS **/

        if (data.src == "commentData") {
          if (data.event == "createComments") {
            let CommentScreendata = data.CommentScreendata;
            let commentsId = data.commentsId;
            this.commentsId = commentsId
            this.selectedCollection = data['seletedCollection'];
            if (this.selectedCollection) {
              this.layoutId = this.selectedCollection['layoutId'];
            }

            if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == 'createCollectionFormActive' && !data.update) {
              var min = -1;
              var max = 100;
              this.pickedMeshObj = {
                "tagShapeId": this.getUIDData(),
                "shapeId": "sphere",
                "position": {
                  "x": 0,//(Math.random() * (max - min + 1)) + min,
                  "y": 0,//(Math.random() * (max - min + 1)) + min,
                  "z": 0,//(Math.random() * (max - min + 1)) + min,
                },
                "parentMeshId": "element-envelope-59__CEROS_200_aft-9e5d5679-5eeb-41d7-83e0-46aa60be4b9b",
                "venue": this.tagServ.port,
              }
              var props = {
                "Location": '',
                "Vessel": '',
                "activity": CommentScreendata.group['name'],
              }
              this.saveNotesData['tagShapeId'] = this.pickedMeshObj.tagShapeId ? this.pickedMeshObj.tagShapeId : '';
              this.filterDate = this.datePipe.transform(data.filterDate, 'MMM,dd,YYY, h:mm a');
              // this.saveNotesData['startDate'] = this.saveNotesData['starDate'] || this.datePipe.transform(new Date(),'MMM,dd,YYY, h:mm a');
              this.saveNotesData['startDate'] = this.datePipe.transform(data.filterDate, 'MMM,dd,YYY, h:mm a');
              this.saveNotesData['typeCollectionId'] = commentsId;
              this.saveNotesData['typeCollection'] = data.typeCollection;
              this.saveNotesData['parentMeshId'] = '';
              this.saveNotesData['shapeId'] = this.selectedCollection['type'];
              this.saveNotesData['props'] = props;
              this.saveNotesData['tagName'] = this.pickedMeshObj.parentMeshId || "";
              this.saveNotesData['description'] = CommentScreendata.comments || "";
              this.saveNotesData['formStatus'] = 'pending';
              this.saveNotesData['status'] = CommentScreendata.status || {};
              this.saveNotesData['group'] = CommentScreendata.group || {};
              this.saveNotesData['group'] = CommentScreendata.group || {};

              this.createNotes()
              // this.chitService.typeCollectionForm = '';
              this.selectedTagItem = this.saveNotesData
            } else if (data.update) {
              this.updateTypeCollectionComments(data);
            } else {
              this.saveNotesData['tagShapeId'] = this.pickedMeshObj.tagShapeId ? this.pickedMeshObj.tagShapeId : '';
              this.saveNotesData['startDate'] = this.saveNotesData['starDate'] || this.datePipe.transform(new Date(), 'MMM,dd,YYY, h:mm a');
              this.saveNotesData['typeCollectionId'] = commentsId;
              this.saveNotesData['typeCollection'] = data.typeCollection;
              this.saveNotesData['formStatus'] = 'pending';
              // this.createNotes()
            }

          }

          if (data.event === 'closeCommentsForm') {
            if (!data.status) {
              this.commentWindowClose(false);
            }
          }
        }



        if (data.src === "slms-slider") {
          if (data.event === 'filterSlms') {
            this.filterDate = this.datePipe.transform(data.sliderDate, 'yyy-MM-dd');
          } else if (data.event === 'filterScrubber') {
            console.log('event', data);
            this.filterDate = this.datePipe.transform(data.endDate, 'yyy-MM-dd');
          }
        }


        if (data.src == "sidepanel") {
          if (data.event == "turnoverNotesCreate") {
            this.selectedCollection = data['seletedCollection'];
            if (this.selectedCollection) {
              this.layoutId = this.selectedCollection['layoutId'];
            }
            if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == 'createCollectionFormActive') {
              var min = -1;
              var max = 100;
              this.pickedMeshObj = {
                "tagShapeId": this.getUIDData(),
                "shapeId": "sphere",
                "position": {
                  "x": 0,//(Math.random() * (max - min + 1)) + min,
                  "y": 0,//(Math.random() * (max - min + 1)) + min,
                  "z": 0,//(Math.random() * (max - min + 1)) + min,
                },
                "parentMeshId": "element-envelope-59__CEROS_200_aft-9e5d5679-5eeb-41d7-83e0-46aa60be4b9b",
                "venue": this.tagServ.port,
              }
              props = {
                "Location": '',
                "Vessel": '',
                "activity": '',
              }
              this.saveNotesData['tagShapeId'] = this.pickedMeshObj.tagShapeId ? this.pickedMeshObj.tagShapeId : '';
              this.saveNotesData['startDate'] = this.saveNotesData['starDate'] || this.datePipe.transform(data['date'], 'MMM,dd,YYY, h:mm a') || this.datePipe.transform(data['seletedCollection'], 'MMM,dd,YYY, h:mm a') || this.datePipe.transform(new Date(), 'MMM,dd,YYY, h:mm a');
              this.saveNotesData['typeCollectionId'] = data.data[0].typeCollectionId;
              this.saveNotesData['typeCollection'] = data.data[0].typeCollection;
              this.saveNotesData['parentMeshId'] = 'Turnover';
              this.saveNotesData['shapeId'] = this.selectedCollection['type'];
              this.saveNotesData['props '] = props;
              this.saveNotesData['description'] = "--";
              this.saveNotesData['formStatus'] = 'pending';
              console.log("turnoverNotes", this.saveNotesData)
              this.createNotes();
              // this.chitService.typeCollectionForm = '';
              this.selectedTagItem = this.saveNotesData;
            } else if (this.selectedTagItem) {
              this.updateTypeCollection(data);
            } else {
              this.saveNotesData['tagShapeId'] = this.pickedMeshObj.tagShapeId ? this.pickedMeshObj.tagShapeId : '';
              this.saveNotesData['startDate'] = this.saveNotesData['starDate'] || this.datePipe.transform(new Date(), 'MMM,dd,YYY, h:mm a');
              this.saveNotesData['typeCollectionId'] = data.data[0].typeCollectionId;
              this.saveNotesData['typeCollection'] = data.data[0].typeCollection;
              this.saveNotesData['formStatus'] = 'pending';
              // this.createNotes()
            }
          }
        }

        if (data.src === "chit") {
          if (data.event == 'chitUpdate') {
            let notesData = data['data'];
            this.updateNoteData(notesData);
          }
          if (data.event === 'chitCreate') {
            this.selectedCollection = data['seletedCollection'];
            if (this.selectedCollection) {
              this.layoutId = this.selectedCollection['layoutId'];
            }

            if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == 'createCollectionFormActive') {
              var min = -1;
              var max = 100;
              this.pickedMeshObj = {
                "tagShapeId": this.getUIDData(),
                "shapeId": "sphere",
                "position": data.data[0]['tagLocation'] || {
                  "x": 0,//(Math.random() * (max - min + 1)) + min,
                  "y": 0,//(Math.random() * (max - min + 1)) + min,
                  "z": 0,//(Math.random() * (max - min + 1)) + min,
                },
                "parentMeshId": "element-envelope-59__CEROS_200_aft-9e5d5679-5eeb-41d7-83e0-46aa60be4b9b",
                "venue": this.tagServ.port,
              }
              props = {
                "Location": data.chit.Location,
                "Vessel": data.chit.Vessel,
                "activity": data.chit.activity,
              }
              this.saveNotesData['tagShapeId'] = this.pickedMeshObj.tagShapeId ? this.pickedMeshObj.tagShapeId : '';
              // this.saveNotesData['startDate'] = this.saveNotesData['starDate'] || this.datePipe.transform(new Date(),'MMM,dd,YYY, h:mm a');
              this.filterDate = this.datePipe.transform(data.filterDate, 'MMM,dd,YYY, h:mm a');
              this.saveNotesData['startDate'] = this.datePipe.transform(data.filterDate, 'MMM,dd,YYY, h:mm a');
              this.saveNotesData['startTime'] = data.chit.Start ? data.chit.Start : '';
              this.saveNotesData['endTime'] = data.chit.End ? data.chit.End : '';
              this.saveNotesData['typeCollectionId'] = data.data[0].typeCollectionId;
              this.saveNotesData['typeCollection'] = data.data[0].typeCollection;
              this.saveNotesData['parentMeshId'] = data.chit.emitterLocation;
              this.saveNotesData['shapeId'] = this.selectedCollection['type'];
              this.saveNotesData['props'] = props;
              this.saveNotesData['tagName'] = data.chit.activity ? data.chit.activity : '';
              this.saveNotesData['description'] = data.data[0]['purpose'] || "";
              this.saveNotesData['formStatus'] = 'pending';

              this.createNotes()
              // this.chitService.typeCollectionForm = '';
              this.selectedTagItem = this.saveNotesData
            } else if (this.selectedTagItem) {
              this.updateTypeCollection(data);
            } else {
              this.saveNotesData['tagShapeId'] = this.pickedMeshObj.tagShapeId ? this.pickedMeshObj.tagShapeId : '';
              this.saveNotesData['startDate'] = this.saveNotesData['starDate'] || this.datePipe.transform(new Date(), 'MMM,dd,YYY, h:mm a');
              this.saveNotesData['typeCollectionId'] = data.data[0].typeCollectionId;
              this.saveNotesData['typeCollection'] = data.data[0].typeCollection;
              this.saveNotesData['formStatus'] = 'pending';
              // this.createNotes()
            }
          }
          if (data.event === 'roundsCreate') {
            this.selectedCollection = data['seletedCollection'];
            if (this.selectedCollection) {
              this.layoutId = this.selectedCollection['layoutId'];
              this.selectedTagItem = this.selectedCollection;
            }
            if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == 'createCollectionFormActive' && this.chitService.typeCollectionForm['type'] == 'Rounds') {
              delete this.pickedMeshObj;
              this.createTypeForm(data);
              delete this.chitService.typeCollectionForm;
              this.selectedTagItem = this.saveNotesData;
            } else if (this.selectedTagItem) {
              this.updateTagShapes(data);
              this.updateTypeCollectisonData(data,true);
            }
          }

          if (data.event === 'TeamsCreate') {
            this.selectedCollection = data['seletedCollection'];
            if (this.selectedCollection) {
              this.layoutId = this.selectedCollection['layoutId'];
              this.selectedTagItem = this.selectedCollection;
            }
            if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == 'createCollectionFormActive' && this.chitService.typeCollectionForm['type'] == 'Teams') {
              delete this.pickedMeshObj;
              this.createTypeForm(data);
              delete this.chitService.typeCollectionForm;
              this.selectedTagItem = this.saveNotesData;
            } else if (this.selectedTagItem) {
              this.updateTagShapes(data);
              this.updateTypeCollectisonData(data,true);
            }
          }
          
          if (data.event === 'ScheduleCreate') {
            this.selectedCollection = data['seletedCollection'];
            if (this.selectedCollection) {
              this.layoutId = this.selectedCollection['layoutId'];
              this.selectedTagItem = this.selectedCollection;
            }
            if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == 'createCollectionFormActive' && this.chitService.typeCollectionForm['type'] == 'Flex') {
              delete this.pickedMeshObj;
              this.createTypeForm(data);
              delete this.chitService.typeCollectionForm;
              this.selectedTagItem = this.saveNotesData;
            }  else if (this.selectedTagItem) {
              this.updateTagShapes(data);
              this.updateTypeCollectisonData(data,true);
            }
          }
          if (data.event == 'visitorUpdate') {
            console.log("visitorUpdate", data['data']);
            let notesData = data['data'];
            this.updateNoteData(notesData);
          }
          if (data.event === 'VisitorCreate') {
            this.selectedCollection = data['seletedCollection'];
            if (this.selectedCollection) {
              this.layoutId = this.selectedCollection['layoutId'];
              this.selectedTagItem = this.selectedCollection;
            }
            if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == 'createCollectionFormActive' && this.chitService.typeCollectionForm['type'] == 'Visitor') {
              delete this.pickedMeshObj;
              this.createTypeForm(data);
              delete this.chitService.typeCollectionForm;
              this.selectedTagItem = this.saveNotesData;
            } else if (this.selectedTagItem) {
              this.updateTagShapes(data);
              this.updateTypeCollectisonData(data,true);
            }
          }
          if (data.event === 'lotocreate') {
            this.selectedCollection = data['seletedCollection'];
            if (this.selectedCollection) {
              this.layoutId = this.selectedCollection['layoutId'];
              this.selectedTagItem = this.selectedCollection;
            }
            if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == 'createCollectionFormActive' && this.chitService.typeCollectionForm['type'] == 'Lockout/Tagout') {
              delete this.pickedMeshObj;
              this.createTypeForm(data);
              delete this.chitService.typeCollectionForm;
              this.selectedTagItem = this.saveNotesData;
            } else if (this.selectedTagItem) {
              this.updateTagShapes(data);
              this.updateTypeCollectisonData(data,true);
            }
          }

          if (data.event === 'hotworkCreate') {
            this.selectedCollection = data['seletedCollection'];
            if (this.selectedCollection) {
              this.layoutId = this.selectedCollection['layoutId'];
              this.selectedTagItem = this.selectedCollection;
            }
            if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == 'createCollectionFormActive' && this.chitService.typeCollectionForm['type'] == 'Hot Workings') {
              delete this.pickedMeshObj;
              this.createTypeForm(data);
              delete this.chitService.typeCollectionForm;
              this.selectedTagItem = this.saveNotesData;
            } else if (this.selectedTagItem) {
              this.updateTagShapes(data);
              this.updateTypeCollectisonData(data,true);
            }
          }

          if (data.event === 'chitDelete') {
            this.updateChitData();
          }
          if (data.event === 'roundsDelete') {
            this.updateChitData();
          }
        }


        if (data.src === "main") {
          if (data.event == 'navyUserInfo') {
            this.loginInfo = data.data;
            this.userRole = this.loginInfo['role'];
            // this.getLayoutData();
            this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
          }
        }
        if (data.src == 'sidepanel') {
          if (data.event == 'stateBoardForm') {
            this.stateBoardForm = data['data'];
          }
          if (data.event === 'schedule') {
            this.scheduleWindowState = data.data;
          }
        }
        if (data.src == "admin-SB") {
          if (data.event == "adminView") {
            let adminSB = data['data'];
            // this.adminStateBoardView=adminSB;
            this.stateBoardView = adminSB;
            this.ctrlServ.stateBoardView = this.stateBoardView;
          }
          if (data.event == "stateBoardForm") {
            this.stateBoardForm = data['data'];
          }
          if (data.event == "stateBoardView") {
            let adminSB = data['data'];
            // let result = adminSB['layoutList'].filter(o1 => this.sidepanelLayoutData.some(o2 => o1['_id'] === o2['_id']));
            console.log("stateBoardView", adminSB);
            this.stateBoardView = adminSB;
            this.ctrlServ.stateBoardView = this.stateBoardView;
            // this.adminStateBoardView=false;
          }
        }
        if (data.src == "dynamic-layout") {
          if (data.event == "closeStateboard") {
            this.stateBoardView = false;
            this.ctrlServ.stateBoardView = this.stateBoardView;
          }
          if (data.event == "closeTurnover") {
            this.stateBoardForm = false;
          }
        }
        // if(data.src=="mobilePanel"){
        //   if(data.event=="closeLayout"){
        //     this.layoutState = false;
        //   }
        // }
        if (data.src == "ship-detail") {
          if (data.event == "set-location-picked-location") {
            let obj = data['data'];
            if (obj) {
              this.addPickedLocation(obj);
            }
          }
        }
        if (data.src == "socketServ") {
          if (data.event == "show-chat-notification") {
            let obj = data['data'];
            if (obj) {
              this.showChatNotification(obj);
            }
          }
        }
      })

    this.broadcastIframeSubs = this.communicationServ.getIframeInstance()
      .subscribe((data: any) => {
        if (data.iframeConnection === "sdms-connection") {
          console.log('visitor-notification')
          if (data.event === 'visitor-notification') {
           console.log('visitor-notification',data);
           this.showVisitorNotificationPoup(data['data']);
          }
        }
      })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1008) {
      // window.lockOrientation('landscape')
      //       console.log( window.screen.orientation)
      // // console.log( window.screen.orientation.lock('landscape'))
      // window.screen.orientation
      //     .lock("landscape")
      //     .then(
      //         success => console.log(success),
      //         failure => console.log(failure)
      //     )
      // console.log( window.screen.orientation)
    }
    if (event.target.innerWidth < 680) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  getUIDData() {
    return Math.random().toString(36).substr(2, 9);
  }

  objectTagClose(tag){
    this.notificationCloseWindow();
    this.broadcastInfo({ src: 'main', event: 'tagEvent', data: tag.tagShapeId, key:false });
  }
  layoutTagClose(e) {
    if (e == 'temp') {
      delete this.pickedMeshObj;
      // delete this.notesElement;
      // delete this.notesComment;
      this.chitWindowState = false;
      this.tagoutWindowState = false;
      this.scheduleWindowState = false;
      this.broadcastInfo({ src: 'sidepanel', event: 'dashboardChit', data: this.scheduleWindowState, key: "dashboard", value: e });
      this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "dashboard", value: e });
      this.broadcastInfo({ src: 'dashboard', event: 'createTagoutDialog', data: false, key: "" });
      this.tagServ.matTagClear();
      this.drawService.clearCutOuts();
      this.drawService.clearSections();
    } else {
      this.broadcastInfo({ src: 'navyApp', event: 'layoutTagClose', data: e });
      this.chitWindowState = false;
      this.tagoutWindowState = false;
      this.scheduleWindowState = false;
      this.broadcastInfo({ src: 'sidepanel', event: 'dashboardChit', data: this.scheduleWindowState, key: "dashboard", value: e });
      this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "dashboard", value: e });
      this.broadcastInfo({ src: 'dashboard', event: 'createTagoutDialog', data: false, key: "" });
    }
  }
  // selectGroup(item, tag= undefined){
  //   this.broadcastInfo({ src: 'tagPoint', event: 'groupCreateTag', data: item, tag : tag});
  // }
  // changeStatus(name){
  //   this.broadcastInfo({ src: 'tagPoint', event: 'statusCreateTag', data: name, });
  // }
  // changeStatusLayout(data, layout, tagPoint, status){
  //   this.broadcastInfo({ src: 'tagPoint', event: 'statusUpdateTag', data: data, key: layout, key1: tagPoint, key2: status });
  // }
  openCommentWindow(item, group) {
    if (item != null) {
      this.selectedTagItem = item;
    }
    this.commetsWindow = true;
    let commentsData = item != null ? item : this.saveNotesData;
    setTimeout(() => {
      this.broadcastInfo({ src: 'navyApp', event: 'statusData', data: this.statusArray, notes: this.saveNotesData, selectedLayoutList: this.selectedLayoutList, commentsData: commentsData });
    }, 100);

  }

  commentWindowClose(e) {
    this.commetsWindow = e;
  }
  compComments: any = '';
  isCommentTyped = false;
  onComment(e) {
    if (this.compComments) {
      this.isCommentTyped = true;
    } else {
      this.isCommentTyped = false;
    }
  }

  typeAction(e, item) {
    console.log(item)
    delete this.chitService.typeCollectionForm;
    this.broadcastInfo({ src: 'sidepanel', event: 'createCollectionTypeForm', data: e, key: item });
  }
  // typeAction(e, item) {
  //   if (item != null) {
  //     this.selectedTagItem = item;
  //   }
  //   if (item == null) {
  //     delete this.selectedTagItem;
  //   }
  //   if (e == 'Chits') {
  //     // this.scheduleWindowState = true;
  //       this.broadcastInfo({ src: 'tag-Point', event: 'dashboardChit', data: this.scheduleWindowState, key: "dashboard", value: e ,item :item});
  //   }
  //   if (e == 'Rounds') {
  //     this.scheduleWindowState = true;
  //     setTimeout(() => {
  //       this.broadcastInfo({ src: 'tag-Point', event: 'schedule', data: this.scheduleWindowState, key: "dashboard", value: e,item :item });
  //     }, 100);
  //   }
  //   if (e == 'Lockout/Tagout') {
  //     this.tagoutWindowState = true;
  //     setTimeout(() => {
  //       this.broadcastInfo({ src: 'tag-Point', event: 'createTagoutDialog', data: true, key: "" ,item :item});
  //     }, 100);
  //   }
  //   if (e == 'Aid') {
  //     // var aidCreate = true;
  //     // this.broadcastInfo({ src: 'main', event: 'aidAreas', data: aidCreate });
  //   }
  //   if (e == 'HazardAreas') {
  //     // var harzardAreaCreate = true;
  //     // this.broadcastInfo({ src: 'main', event: 'hazardAreas', data: harzardAreaCreate });
  //     // console.log("Comming Soon")
  //   }
  //   if (e == 'Kiosks') {
  //     console.log("Comming Soon")
  //   }
  //   if (e == 'LaydownAreas') {
  //     console.log("Comming Soon")
  //   }
  // }
  // viewDataTypeDetails(e, id, item){
  //   if (item != null) {
  //     this.selectedTagItem = item;
  //   }
  //   if (item == null) {
  //     delete this.selectedTagItem;
  //   }
  //   if (e == 'Chits') {
  //     this.scheduleWindowState = true;
  //     this.chitWindowState = true;
  //     setTimeout(() => {
  //       this.broadcastInfo({ src: 'sidepanel', event: 'chitViewForm', data: id, key: "dashboard", });
  //     }, 50)
  //   }
  //   if (e == 'Rounds') {
  //     this.scheduleWindowState = true;
  //     setTimeout(() => {
  //       // this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "dashboard", value: e });
  //       // this.broadcastInfo({ src: 'sidepanel', event: 'chitViewForm', data: id, key: "dashboard", });
  //       this.broadcastInfo({ src: 'sidepanel', event: 'schedule', data: this.scheduleWindowState, key: "dashboard" });
  //       this.broadcastInfo({ src: 'sidepanel', event: 'scheduleDashboardDataView', data: id, key: "", });
  //     }, 50)
  //   }
  // }
  viewDataTypeDetails(e, id, item) {
    delete this.chitService.typeCollectionForm;
    this.broadcastInfo({ src: 'sidepanel', event: 'viewCollectionTypeForm', data: e, key: id, key1: item });
  }
  // chitDeleteActivity(chitId) {
  //   this.chitService.deleteChitActivity(chitId)
  //     .subscribe((res) => {
  //       this.chitWindowState = false;
  //     }, error => {
  //       console.error('There was an error!');
  //     })
  // }
  // roundseDeleteActivity(roundsId) {
  //   this.chitService.deleteRounds(roundsId)
  //     .subscribe((res) => {
  //       this.scheduleWindowState = false
  //     }, error => {
  //       console.error('There was an error!');
  //     })
  // }
  // select group based by create tag
  selectGroup(ele, tagList = undefined) {
    if (!tagList && !this.saveNotesData._id) {
      this.saveNotesData['props'] = {}
      delete ele.groupList;
      this.saveNotesData["group"] = ele;
      this.saveNotesData['props']['activity'] = ele.name;
      this.saveNotesData['tagName'] = this.pickedMeshObj.parentMeshId || "";
      this.saveNotesData['tagShapeId'] = this.pickedMeshObj.tagShapeId || ''
      this.saveNotesData['startDate'] = this.saveNotesData['starDate'] || this.filterDate || new Date()
      if (this.progressStatus) {
        this.progressStatus = false;
        this.createMarker();
        this.createNotes();
      }
    } else {
      if (!tagList) {
        tagList = this.saveNotesData
      }
      delete ele.groupList;
      tagList['group'] = ele;
      tagList['props']['activity'] = ele.name;
      if (this.progressStatus) {
        this.progressStatus = true
        this.updateNotes(tagList, 'tag')
      }
    }

  }
  getUserData() {
    this.configService.getProjectConfig().then((project_config: any) => {
      this.chitService.getUserData().subscribe(
        (response) => {
          this.usersDetails = response;
          this.broadcastInfo({ src: 'navyApp', event: 'userDetails', data: this.usersDetails });
        },
        error => {
          console.error('Request failed with error')
        })
    })
  }
  // select status based by create tag
  commentDetails = []
  changeStatus(data) {
    this.saveNotesData['tagName'] = this.pickedMeshObj.parentMeshId || "";
    var statusId = this.statusListData.filter(obj => obj.name == data)
    var statusIdData = statusId[0]._id;
    var statusIdx = this.statusListData.findIndex(obj => obj.name == data)
    if (this.layoutHeading) {
      this.commentDetails = [];
      this.commentDetails.push({ "status": this.statusListData[statusIdx], "startDate": new Date(), "tagShapeId": this.pickedMeshObj.tagShapeId, group: this.saveNotesData["group"] || {} })
      if (!this.saveNotesData._id) {
        this.saveNotesData = this.commentDetails[0];
        if (this.progressStatus) {
          this.progressStatus = false
          this.createMarker();
          this.createNotes();
        }

      } else {
        this.saveNotesData['status'] = this.statusListData[statusIdx]
        if (this.progressStatus) {
          this.progressStatus = false;
          this.updateNotes(this.saveNotesData)
        }
      }
    } else {
      this.commentDetails = [];
      this.commentDetails.push({ "status": this.statusListData[statusIdx], "startDate": new Date(), "tagShapeId": this.pickedMeshObj.tagShapeId, group: this.saveNotesData["group"] || {} })
      this.temporaryCommentData = this.commentDetails[0];
      this.saveNotesData = this.commentDetails[0];
      //this.broadcastInfo({ src: 'material', event: 'openDialogConfirmLayoutName', data: this.temporaryCommentData });
    }
  }
  statusUpdated;
  changeStatusLayout(data, layout, tagPoint, status) {
    var statusId = this.statusListData.filter(obj => obj.name == status)
    var statusIdData = statusId[0]._id;
    this.statusUpdated = status;
    var statusIDX = this.statusListData.findIndex(obj => obj.name == status)
    data.status = this.statusListData[statusIDX]
    data.statusId = statusIdData;
    var updateCommentList = {
      data: data, "layOut": layout, "selectTag": tagPoint
    }
    console.log(updateCommentList)
    if (this.progressStatus) {
      this.progressStatus = false
      this.updateComment(updateCommentList)
    }

  }
  statusChanges(event) {
    this.saveNotesData['tagName'] = this.pickedMeshObj.parentMeshId || "";
    this.statusUpdated = event
  }
  updateComment(data) {
    this.commentDetails = []
    var comments = {
      name: this.layoutName,
      type: this.typeCollection,
      groupCollection: this.groupCollection,
      statusCollection: this.statusCollection,
      shapeId: this.shapeCollection
    }
    console.log("notes data", data)

    if (data.selectTag) {
      this.selectedTagItem = data.selectTag;
    }
    if (!this.selectedLayoutList['tagPoints']) {
      this.selectedLayoutList['tagPoints'] = []
    }
    let idx = this.selectedLayoutList['tagPoints'].findIndex(e => e.tagShapeId == this.selectedTagItem.tagShapeId);
    if (idx > -1) {
      this.selectedLayoutList['tagPoints'][idx]['tagComments'] = data.data;
      this.commentDetails.push(data.data)
      this.updateNotes(data.data)
    } else {
      var tagPoint = this.selectedTagItem;
      tagPoint['tagComments'] = data.data;
      this.selectedLayoutList['tagPoints'].push(tagPoint);
      this.updateLayout(this.selectedLayoutList['_id'], this.selectedLayoutList);
    }
  }

  updateDescription(data) {
    this.commentDetails = []
    if (data.selectTag) {
      this.selectedLayoutList = data.layOut;
      this.selectedTagItem = data.selectTag;
    }
    if (!this.selectedLayoutList['tagPoints']) {
      this.selectedLayoutList['tagPoints'] = []
    }
    let idx = this.selectedLayoutList['tagPoints'].findIndex(e => e.tagShapeId == this.selectedTagItem.tagShapeId);
    let tagpoint = this.selectedLayoutList['tagPoints'].filter(e => e.tagShapeId == this.selectedTagItem.tagShapeId);

    if (idx > -1) {
      var comments = {
        collectionRefId: this.selectedLayoutList['tagPoints'][idx]['tagComments'].collectionRefId,
        description: data.data.description ? data.data.description : this.selectedLayoutList['tagPoints'][idx]['tagComments'].description,
        layoutId: this.selectedLayoutList['tagPoints'][idx]['tagComments'].layoutId,
        markerId: this.selectedLayoutList['tagPoints'][idx]['tagComments'].markerId,
        startDate: this.selectedLayoutList['tagPoints'][idx]['tagComments'].startDate,
        status: data.data.status ? data.data.status : this.selectedLayoutList['tagPoints'][idx]['tagComments'].status,
        group: this.selectedLayoutList['tagPoints'][idx]['tagComments'].group || "",
        statusId: this.selectedLayoutList['tagPoints'][idx]['tagComments'].statusId,
        tagShapeId: this.selectedLayoutList['tagPoints'][idx]['tagComments'].tagShapeId,
        _id: this.selectedLayoutList['tagPoints'][idx]['tagComments']._id,
        typeCollectionId: this.selectedLayoutList['tagPoints'][idx]['tagComments'].typeCollectionId,
        typeCollection: this.selectedLayoutList['tagPoints'][idx]['tagComments'].typeCollection,
        uploadFile: data.data.uploadFile ? data.data.uploadFile : [],
        userList: data.data.userList ? data.data.userList : [],
        formStatus: this.selectedLayoutList['tagPoints'][idx]['tagComments'].formStatus,
      }
      let commentsId = this.selectedLayoutList['tagPoints'][idx]['tagComments']._id;
      this.selectedLayoutList['tagPoints'][idx]['tagComments'] = comments
      this.commentDetails.push(comments)
      this.updateNotesData(commentsId, comments)
    } else {
      var tagPoint = this.selectedTagItem;
      tagPoint['tagComments'] = comments;
      this.selectedLayoutList['tagPoints'].push(tagPoint);
      this.updateLayout(this.selectedLayoutList['_id'], this.selectedLayoutList);
    }
  }
  editCollection: any = false;
  editCollectionData: any
  layoutHeadingName: any;
  typeCollection: any;
  groupCollection: any;
  groupCollectionData: any = [];
  statusCollection: any;
  statusCollectionData: any = [];;
  shapeCollection: any;
  shapeCollectionIcon: any;
  shapeCollectionImg: any;
  layoutShapeModel = false;
  layoutModel = false;

  onSaveLayout() {
    this.LayoutMode = true;
    if (this.editCollection) {
      let idx = this.layoutData.findIndex(el => el['layoutId'] === this.editCollectionData['layoutId']);
      if (idx > -1) {
        this.editCollectionData['layoutName'] = this.layoutHeadingName;
        this.editCollectionData['_id'] = this.editCollectionData['layoutId'];
        delete this.editCollectionData['type'];
        delete this.editCollectionData['layoutId'];
        console.log(this.editCollectionData, this.layoutData[idx]);
        this.updateCollection(this.editCollectionData);
      }
    } else {
      if (this.layoutHeading) {
        const idx = this.layoutData.findIndex(el => el.layoutName.split(" ").join("").toLowerCase() === this.layoutName.split(" ").join("").toLowerCase());
        if (idx > -1) {
          this.createMarker();
          this.createNotes();
        }
      } else {
        var data = {
          layoutName: this.layoutHeadingName,
          type: this.typeCollection,
          groupCollection: this.groupCollectionData,
          statusCollection: this.statusCollectionData,
          shapeId: this.shapeCollection
        }
        this.tagServ.matTagClear();
        this.createLayout(data, true);
      }
    }
    this.layoutHeading = this.layoutName;
    this.layoutWindowClose();
  }
  updateCollection(data) {
    this.updateLayout(data['_id'], data);
  }
  layoutWindowClose() {
    // this.showLayout = false;
    this.editCollection = false;
    this.layoutModel = false;
    delete this.temporaryCommentData;
  }
  openLayout() {
    this.layoutModel = true;
  }
  selectTypeData(e) {
    console.log("Type Collection", e)
    this.typeCollection = e;
  }
  selectGroupData(e) {
    this.groupCollectionData = [];
    e.forEach(ele => {
      if (ele) {
        this.groupList.forEach(element => {
          if (element.name == ele) {
            this.groupCollectionData.push(element)
          }
        });
      }

    });
    console.log("Group Collection", this.groupCollectionData)
    this.groupCollection = e;
    var data = e.filter(item => item == 'undefined');
    if (data == 'undefined') {
      this.groupCollection.splice(data, 1);
    } else {
      this.groupCollection = e;
    }
  }
  selectStatusData(e) {
    this.statusCollectionData = [];
    e.forEach(ele => {
      if (ele) {
        this.statusListData.forEach(element => {
          if (element.name == ele) {
            this.statusCollectionData.push(element)
          }
        });
      }
    });
    console.log("Status Collection", this.statusCollectionData)
    this.statusCollection = e;
    var data = e.filter(item => item == 'undefined');
    if (data == 'undefined') {
      this.statusCollection.splice(data, 1);
    } else {
      this.statusCollection = e;
    }
  }

  shapeWindowOpen() {
    this.layoutShapeModel = true;
    this.layoutModel = false;
  }
  shapeWindowClose() {
    this.layoutShapeModel = false;
    this.layoutModel = true;
  }
  selectShapeData() {
    this.shapeCollection = this.shapeCollectionIcon;
    this.layoutShapeModel = false;
    this.layoutModel = true;
  }
  selectShapeIcon(e) {
    this.shapeCollectionIcon = e.id;
    this.shapeCollectionImg = e.icon;
  }
  handleLayoutSave() {
    // this.editCollection = false;
    this.layoutHeading = ""
    this.layoutName = ""
    this.saveLayoutData = {}
    this.onSaveLayout();
  }
  editGroupData: any;
  groupName: any;
  groupStatusColor: any;
  groupData: string;

  openGroupPanel(e, data = undefined) {
    this.groupState = e;
    if (this.groupState == 'edit') {
      this.editGroupData = data;
      this.groupName = data.name;
      this.groupStatusColor = data.color.indexOf('rgba(') > -1 ? this.rgba2hex(data.color) : data.color.indexOf('rgb(') > -1 ? this.rgb2hex(data.color) : data.color;
    } else {
      this.groupStatusColor = this.getRandomColor();
    }
    this.groupWindow = true;
  }

  rgba2hex(color) {
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
    const hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)}`;
    return hex;
  }
  rgb2hex(rgb) {
    var rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
    var result, r, g, b, hex = "";
    if ((result = rgbRegex.exec(rgb))) {
      r = this.componentFromStr(result[1], result[2]);
      g = this.componentFromStr(result[3], result[4]);
      b = this.componentFromStr(result[5], result[6]);

      hex = "0x" + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }
    return hex;
  }
  componentFromStr(numStr, percent) {
    var num = Math.max(0, parseInt(numStr, 10));
    return percent ?
      Math.floor(255 * Math.min(100, num) / 100) : Math.min(255, num);
  }
  groupWindowClose() {
    this.groupWindow = false;
    delete this.editGroupData;
    delete this.groupName, this.groupState, this.groupData;
  }
  updateNoteData(data) {
    // if (idx > -1) {
    // var comments = {
    //   collectionRefId: this.selectedLayoutList['Notes'][idx].collectionRefId,
    //   description: data.CommentScreendata ? data.CommentScreendata.comments  : this.selectedLayoutList['Notes'][idx].description,
    //   layoutId: this.selectedLayoutList['Notes'][idx].layoutId,
    //   markerId: this.selectedLayoutList['Notes'][idx].markerId,
    //   startDate: this.selectedLayoutList['Notes'][idx].startDate,
    //   status: this.selectedLayoutList['Notes'][idx].status,
    //   group: this.selectedLayoutList['Notes'][idx].group || "",
    //   statusId: this.selectedLayoutList['Notes'][idx].statusId,
    //   tagShapeId: this.selectedLayoutList['Notes'][idx].tagShapeId,
    //   _id: this.selectedLayoutList['Notes'][idx]._id,
    //   typeCollectionId: data.data != undefined && data.data[0].typeCollectionId ? data.data[0].typeCollectionId : this.selectedLayoutList['Notes'][idx].typeCollectionId,
    //   typeCollection: data.data != undefined && data.data[0].typeCollection ? data.data[0].typeCollection : this.selectedLayoutList['Notes'][idx].typeCollection,
    //   parentMeshId: this.selectedLayoutList['Notes'][idx].parentMeshId,
    //   shapeId: this.selectedLayoutList['Notes'][idx].ShapeId,
    //   props: this.selectedLayoutList['Notes'][idx].props,
    //   tagName: this.selectedLayoutList['Notes'][idx].tagName,
    //   formStatus: this.selectedLayoutList['Notes'][idx].formStatus,
    // }
    // let commentsId = this.selectedLayoutList['Notes'][idx]._id;
    // this.selectedLayoutList['Notes'][idx] = comments
    // this.commentDetails.push(comments)
    let commentsId = data['_id'];
    let comments = data;
    if (commentsId && comments) {
      this.updateNotesData(commentsId, comments);
    }
  }
  updateTypeCollection(data) {
    this.commentDetails = []
    if (data.selectTag) {
      this.selectedLayoutList = data.layOut;
      this.selectedTagItem = data.selectTag;
    }
    if (!this.selectedLayoutList['Notes']) {
      this.selectedLayoutList['Notes'] = []
    }
    let idx = this.selectedLayoutList['Notes'].findIndex(e => e.tagShapeId == this.selectedTagItem.tagShapeId);
    let tagpoint = this.selectedLayoutList['Notes'].filter(e => e.tagShapeId == this.selectedTagItem.tagShapeId);
    if (idx > -1) {
      var comments = {
        collectionRefId: this.selectedLayoutList['Notes'][idx].collectionRefId,
        description: data.CommentScreendata ? data.CommentScreendata.comments : this.selectedLayoutList['Notes'][idx].description,
        layoutId: this.selectedLayoutList['Notes'][idx].layoutId,
        markerId: this.selectedLayoutList['Notes'][idx].markerId,
        startDate: this.selectedLayoutList['Notes'][idx].startDate,
        status: this.selectedLayoutList['Notes'][idx].status,
        group: this.selectedLayoutList['Notes'][idx].group || "",
        statusId: this.selectedLayoutList['Notes'][idx].statusId,
        tagShapeId: this.selectedLayoutList['Notes'][idx].tagShapeId,
        _id: this.selectedLayoutList['Notes'][idx]._id,
        typeCollectionId: data.data != undefined && data.data[0].typeCollectionId ? data.data[0].typeCollectionId : this.selectedLayoutList['Notes'][idx].typeCollectionId,
        typeCollection: data.data != undefined && data.data[0].typeCollection ? data.data[0].typeCollection : this.selectedLayoutList['Notes'][idx].typeCollection,
        parentMeshId: this.selectedLayoutList['Notes'][idx].parentMeshId,
        shapeId: this.selectedLayoutList['Notes'][idx].ShapeId,
        props: this.selectedLayoutList['Notes'][idx].props,
        tagName: this.selectedLayoutList['Notes'][idx].tagName,
        formStatus: this.selectedLayoutList['Notes'][idx].formStatus,
      }
      let commentsId = this.selectedLayoutList['Notes'][idx]._id;
      this.selectedLayoutList['Notes'][idx] = comments
      this.commentDetails.push(comments)
      if (commentsId && comments) {
        this.updateNotesData(commentsId, comments);
      }
    } else {
      var tagPoint = this.selectedTagItem;
      tagPoint['tagComments'] = comments;
      this.selectedLayoutList['Notes'].push(tagPoint);
      if (this.selectedLayoutList && this.selectedLayoutList['_id']) {
        this.updateLayout(this.selectedLayoutList['_id'], this.selectedLayoutList);
      }
    }
  }

  updateTypeCollectisonData(data,booflagStatus = false) {
    this.commentDetails = []
    if (data.selectTag) {
      this.selectedLayoutList = data.layOut;
      this.selectedTagItem = data.selectTag;
    }
    if (!this.selectedLayoutList['Notes']) {
      this.selectedLayoutList['Notes'] = []
    }
    let idx = this.selectedLayoutList['Notes'].findIndex(e => e.tagShapeId == this.selectedTagItem.tagShapeId);
    let tagpoint = this.selectedLayoutList['Notes'].filter(e => e.tagShapeId == this.selectedTagItem.tagShapeId);
    var description = data.data
    var props = {
      "Location": data.chit.Location,
      "Vessel": data.chit.Vessel,
      "activity": data.chit.activity,
      "tagLocation":data.data[0]['tagLocation'] || {
        "x": 0,//(Math.random() * (max - min + 1)) + min,
        "y": 0,//(Math.random() * (max - min + 1)) + min,
        "z": 0,//(Math.random() * (max - min + 1)) + min,
      }
    }
    if (this.selectedTagItem) {
      var comments = {
        collectionRefId: this.selectedTagItem.collectionRefId ? this.selectedTagItem.collectionRefId: '',
        description: description[0].purpose,
        layoutId: this.selectedTagItem.layoutId,
        markerId: this.selectedTagItem.markerId,
        startDate: this.selectedTagItem.startDate,
        status: this.selectedTagItem.status,
        group: this.selectedTagItem.group || "",
        statusId: this.selectedTagItem.statusId,
        tagShapeId: this.selectedTagItem.tagShapeId,
        _id: this.selectedTagItem._id,
        typeCollectionId: this.selectedTagItem.typeCollectionId,
        typeCollection: this.selectedTagItem.typeCollection,
        parentMeshId: this.selectedTagItem.parentMeshId,
        shapeId: this.selectedTagItem.ShapeId,
        props: props,
        tagName: this.selectedTagItem.tagName,
        formStatus: this.selectedTagItem.formStatus,
      }
      let commentsId = this.selectedTagItem._id;
      // this.selectedLayoutList['Notes'][idx] = comments
      this.commentDetails.push(comments)
      if (commentsId && comments) {
        this.updateNotesData(commentsId, comments,booflagStatus);
      }
    } else {
      var tagPoint = this.selectedTagItem;
      tagPoint['tagComments'] = comments;
      this.selectedLayoutList['Notes'].push(tagPoint);
      if (this.selectedLayoutList && this.selectedLayoutList['_id']) {
        this.updateLayout(this.selectedLayoutList['_id'], this.selectedLayoutList);
      }
    }
  }

  updateTypeCollectionComments(data) {
    this.commentDetails = []
    if (data.selectTag) {
      this.selectedLayoutList = data.layOut;
      this.selectedTagItem = data.selectTag;
    }
    if (!this.selectedLayoutList['Notes']) {
      this.selectedLayoutList['Notes'] = []
    }
    let idx = this.selectedLayoutList['Notes'].findIndex(e => e.tagShapeId == this.selectedTagItem.tagShapeId);
    let tagpoint = this.selectedLayoutList['Notes'].filter(e => e.tagShapeId == this.selectedTagItem.tagShapeId);
    if (idx > -1) {
      var comments = {
        collectionRefId: this.selectedLayoutList['Notes'][idx].collectionRefId,
        description: data.CommentScreendata ? data.CommentScreendata.comments : this.selectedLayoutList['Notes'][idx].description,
        layoutId: this.selectedLayoutList['Notes'][idx].layoutId,
        markerId: this.selectedLayoutList['Notes'][idx].markerId,
        startDate: this.selectedLayoutList['Notes'][idx].startDate,
        status: this.selectedLayoutList['Notes'][idx].status,
        group: this.selectedLayoutList['Notes'][idx].group || "",
        statusId: this.selectedLayoutList['Notes'][idx].statusId || "",
        tagShapeId: this.selectedLayoutList['Notes'][idx].tagShapeId || "",
        _id: this.selectedLayoutList['Notes'][idx]._id,
        typeCollectionId: this.commentsId,
        typeCollection: data['typeCollection'],
        parentMeshId: this.selectedLayoutList['Notes'][idx].parentMeshId,
        shapeId: this.selectedLayoutList['Notes'][idx].ShapeId || "",
        props: this.selectedLayoutList['Notes'][idx].props,
        tagName: this.selectedLayoutList['Notes'][idx].tagName,
        formStatus: this.selectedLayoutList['Notes'][idx].formStatus,
      }
      let commentsId = this.selectedLayoutList['Notes'][idx]._id;
      this.selectedLayoutList['Notes'][idx] = comments
      this.commentDetails.push(comments)
      if (commentsId && comments) {
        this.updateNotesData(commentsId, comments);
      }
    } else {
      var tagPoint = this.selectedTagItem;
      tagPoint['tagComments'] = comments;
      this.selectedLayoutList['Notes'].push(tagPoint);
      if (this.selectedLayoutList && this.selectedLayoutList['_id']) {
        this.updateLayout(this.selectedLayoutList['_id'], this.selectedLayoutList);
      }
    }
  }

  updateChitData() {
    this.commentDetails = []
    if (!this.selectedLayoutList['tagPoints']) {
      this.selectedLayoutList['tagPoints'] = []
    }
    let idx = this.selectedLayoutList['tagPoints'].findIndex(e => e.tagShapeId == this.selectedTagItem.tagShapeId);
    if (idx > -1) {
      var comments = {
        collectionRefId: this.selectedLayoutList['tagPoints'][idx]['tagComments'].collectionRefId,
        description: this.selectedLayoutList['tagPoints'][idx]['tagComments'].description,
        layoutId: this.selectedLayoutList['tagPoints'][idx]['tagComments'].layoutId,
        markerId: this.selectedLayoutList['tagPoints'][idx]['tagComments'].markerId,
        startDate: this.selectedLayoutList['tagPoints'][idx]['tagComments'].startDate,
        status: this.selectedLayoutList['tagPoints'][idx]['tagComments'].status,
        group: this.selectedLayoutList['tagPoints'][idx]['tagComments'].group || "",
        statusId: this.selectedLayoutList['tagPoints'][idx]['tagComments'].statusId,
        tagShapeId: this.selectedLayoutList['tagPoints'][idx]['tagComments'].tagShapeId,
        _id: this.selectedLayoutList['tagPoints'][idx]['tagComments']._id,
        typeCollectionId: '',
        typeCollection: this.selectedLayoutList['tagPoints'][idx]['tagComments'].typeCollection,
      }
      let commentsId = this.selectedLayoutList['tagPoints'][idx]['tagComments']._id;
      this.selectedLayoutList['tagPoints'][idx]['tagComments'] = comments
      this.commentDetails.push(comments)
      // this.updateNotesData(commentsId, comments)
    } else {
      var tagPoint = this.selectedTagItem;
      tagPoint['tagComments'] = comments;
      this.selectedLayoutList['tagPoints'].push(tagPoint);
      // this.updateLayout(this.selectedLayoutList['_id'], this.selectedLayoutList);
    }
  }

  // Api Communication function

  // getLayoutData(data = undefined){
  //   this.progressStatus = true;
  //   this.configService.getLayoutData().then((response: any) => {
  //     console.log("getSDMSLayoutData ", response);
  //     this.layoutData = response.filter(e => e.port == this.cfbport);
  //     console.log("getLayoutData-port ", this.layoutData);
  //     this.broadcastInfo({ src: 'navyApp', event: 'layoutData', data: this.layoutData , createNotes : data });
  //   }).catch((error) => {
  //     // console.log("error ", error)
  //   });
  // }
  createLayout(data, boo = false) {
    data['venue'] = this.tagServ.port,
      console.log(data)
    this.configService.createLayout(data).then(layout => {
      if (layout) {
        console.log("Layout created ", layout);
        this.layoutId = layout['ops'][0]._id
        this.createMarker();
        this.createNotes();
        // this.getLayoutData()
        this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
      }
    }).catch((error) => {
      console.log("error ", error);
    });
  }
  updateLayout(_id, conedata) {
    this.progressStatus = true;
    this.configService.updateLayout(_id, conedata).then(data => {
      if (data) {
        console.log("Layout updated ", data);
        // this.getLayoutData();
        this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
        this.broadcastInfo({ src: 'khaservice', event: 'hideloader', data: '' });
        this.broadcastInfo({ src: 'tagPoint', event: 'layoutData', data: true });
      }
    }).catch((error) => {
      console.log("error ", error);
    });
  }
  deleteLayout(_id, layout) {
    this.configService.deleteLayoutData(_id, { _id: layout['_id'] }).then(data => {
      if (data) {
        console.log("Layout deleted ", data);
        // this.getLayoutData();
        this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
      }
    }).catch((error) => {
      console.log("error ", error);
    });
  }

  createMarker() {
    let markerData = {};
    let marker = this.drawService.getCutMarkersData();
    if (marker.length > 0) {
      markerData = {
        "poistion": marker[0].path || [],
        "parentMeshId": marker[0].pid || '',
        "color": marker[0].color || "",
        "markerId": marker[0].uid || "",
        "cutoff": marker[0].cutoff || "",
        "cutplane": marker[0].cutplane || "",
        "mwidth": marker[0].mwidth || "",
        "fill": marker[0].fill || "",
        "inorm": marker[0].inorm || ''
      }
      this.markerId = markerData['markerId']
      this.configService.createMarker(markerData).then(marker => {
        console.log(marker)
      }).catch((error) => {
        console.log("error ", error);
      });
    }
  }

  createTagShape() {
    if (this.pickedMeshObj) {
      this.pickedMeshObj['markerId'] = this.markerId || ''
      this.pickedMeshObj['shapeId'] = this.pickedMeshObj['shapeId'] == 'shpere' ? 'sphere' : this.pickedMeshObj['shapeId']
      this.configService.createTagShape(this.pickedMeshObj).then(tagShape => {
        console.log(tagShape)
        this.progressStatus = true;
        // this.getLayoutData(tagShape['ops'][0])
        // this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
        if (this.selectedCollection) {
          // this.broadcastInfo({ src: 'tagPoints', event: 'chitNotify', data: '' });
        }        
        this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: tagShape['ops'][0] });


      }).catch((error) => {
        console.log("error ", error);
      });
    }
  }

  updateTagShape() {
    if (this.pickedMeshObj) {
      this.configService.updateTagShapeId(this.pickedMeshObj).subscribe(tagShape => {
        console.log(tagShape)
        this.progressStatus = true;
        if (this.selectedCollection) {
        }
        if (tagShape['value']) {
          let id = "layout_" + tagShape['value']['tagShapeId'];
          let position = this.pickedMeshObj['position'];
          let shapeId = this.pickedMeshObj['shapeId'] || '';
          if (id && position && shapeId) {
            this.tagServ.modifyElement(id, shapeId, position);
            this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: tagShape['value'] });
          }
        }
      })
    }
  }

  createNotes() {
    if (this.saveNotesData && Object.keys(this.saveNotesData).length != 0) {
      this.saveNotesData['layoutId'] = this.layoutId;
      this.saveNotesData['markerId'] = this.markerId || ''
      this.configService.createNotes(this.saveNotesData).then(saveNotesData => {
        this.saveNotesData = saveNotesData['ops'][0]
        this.broadcastInfo({ src: 'navyApp', event: 'layoutNotesData', data: this.saveNotesData });
        this.createTagShape();
        if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == "createCollectionFormActive" && this.chitService.typeCollectionForm['type'] == 'Turnover') {
          console.log("turnovercreate");
          this.broadcastInfo({ src: 'tagPoint', event: 'turnoverSheetCreate', data: this.saveNotesData });
          delete this.chitService.typeCollectionForm;
        }
      }).catch((error) => {
        console.log("error ", error);
      });
    }
  }

  updateNotes(data, status = null) {
    this.configService.updateNotes(data._id, data).then(data => {
      if (data) {
        this.progressStatus = true;
        this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '', status: status != null ? status : '' });
      }
    }).catch((error) => {
      console.log("error ", error);
    });
  }

  updateNotesData(id, data,booflagStatus = false) {
    this.saveNotesData.description = data.description;
    this.configService.updateNotes(id, data).then(data => {
      if (data) {
        this.saveNotesData = data["value"]
        this.broadcastInfo({ src: 'navyApp', event: 'layoutNotesData', data: this.saveNotesData });
        if (booflagStatus) {
          this.updateTagShape();
        }
        this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
      }
    }).catch((error) => {
      console.log("error ", error);
    });
  }

  deleteNotes(data) {
    var layoutData = data;
    if (layoutData.tagShapeId) {
      this.configService.deleteTagShape(layoutData.tagShapeId).then(data => {
      }).catch((error) => {
        console.log("error ", error);
      });
    }
    if (layoutData.markerId) {
      this.configService.deleteMarker(layoutData.markerId).then(data => {
      }).catch((error) => {
        console.log("error ", error);
      });
    }
    if (layoutData.tagComments._id) {
      this.configService.deleteNotes(layoutData.tagComments._id).then(data => {
        if (data) {
          console.log("Layout deleted ", data);
          // this.getLayoutData();
          this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
        }
      }).catch((error) => {
        console.log("error ", error);
      });
    }
  }
  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  statusPopWindowClose() {
    this.statusWindow = false;
    // delete this.editGroupData;
    // delete this.groupName, this.groupState, this.groupData;
  }
  onSaveStatus() {
    var data = { "name": "", "value": "" };
    data['name'] = this.statusName;
    data['value'] = this.statusColor;
    if (this.statusId) {
      this.configService.updateStatus(this.statusId, data).then(data => {
        if (data) {
          console.log("Status updated ", data);
          this.statusWindow = false;
          delete this.statusName;
          delete this.groupState;
          delete this.statusColor;
          delete this.statusId;
          this.getStatusData()
          this.broadcastInfo({ src: 'materaial', event: 'updateStatusData', data: "reConstructTagJson" });
        }
      }).catch((error) => {
        console.log("error ", error);
      });
    } else {
      const found = this.statusListData.some(el => el.name.split(" ").join("").toLowerCase() === this.statusName.split(" ").join("").toLowerCase());
      if (!found) {
        this.configService.createStatus(data).then(data => {
          if (data) {
            console.log("Status created ", data);
            this.statusWindow = false;
            delete this.statusName;
            delete this.groupState
            delete this.statusColor;
            this.getStatusData()
            console.log(this.statusName, this.statusColor);
            this.broadcastInfo({ src: 'kys', event: 'updateStatusData', data: "reConstructTagJson" });
            // if (this.statusTag) {
            //   this.changeStatus(this.statusName);
            // }
            // this.statusTag = false;
          }
        }).catch((error) => {
          console.log("error ", error);
        });
      } else {
        this.broadcastInfo({ src: 'coursePanel', event: 'openDialog', data: "Status name already exists !!" });
        this.statusWindow = true;
      }
    }
  }
  deleteStatusData(_id) {
    this.configService.deleteStatus(_id).then(data => {
      if (data) {
        console.log("Status deleted ", data);
        this.getStatusData()
        this.broadcastInfo({ src: 'kys', event: 'updateStatusData', data: "reConstructTagJson" });
      }
    }).catch((error) => {
      console.log("error ", error);
    });
  }
  statusTag: any = false;
  groupState: any;
  openStatusPanel() {
    this.btnStatus = 'Create';
    this.statusHeading = 'Create Status';
    this.statusTag = true;
    this.statusWindow = true;
  }
  statusUpdate(statusId) {
    var data = { "name": "", "value": "" };
    data['name'] = this.statusName;
    data['value'] = this.statusColor;
    this.configService.updateStatus(statusId, data).then(data => {
      if (data) {
        console.log("Status updated ", data);
        this.statusWindow = false;
        delete this.statusName;
        delete this.groupState
        delete this.statusColor;
        this.getStatusData()
        this.broadcastInfo({ src: 'kys', event: 'updateStatusData', data: "reConstructTagJson" });
      }
    }).catch((error) => {
      console.log("error ", error);
    });
  }
  getStatusData() {
    this.configService.getStatusData().then((response: any) => {
      console.log("getStatusData ", response);
      this.statusListData = response;
      //this.statusArray = response;
      this.broadcastInfo({ src: 'navyApp', event: 'updateStatus', data: this.statusListData });
      setTimeout(() => {
        // this.statusUpdateDetails();
      });
    }).catch((error) => {
      console.log("error ", error);
    });
  }

  onSaveGroup() {
    console.log(this.groupName, this.groupData);
    if (this.groupState == 'new') {
      // let uId=this.getUID();
      // var id ="gp"+uId;
      // var data = { "name": "", "groupList": [], "color": "" };
      // // data['group_id'] = id;
      // data['name'] = this.groupName;
      // data['color'] = this.groupStatusColor;
      // const found = this.groupList.some(el => el.name.split(" ").join("").toLowerCase() === this.groupName.split(" ").join("").toLowerCase());
      // // data['groupList'].push(this.groupData);
      // // this.groupList.push(data);
      // // this.broadcastInfo({ src: 'kys', event: 'createGroup', data:this.groupList });
      // if (!found) {
      //   this.createGroup(data, (obj) => {
      //     console.log('createGroup-id', obj);
      //     setTimeout(() => {
      //       let idx = this.groupList.findIndex(e => e['_id'] == obj['insertedId']);
      //       console.log('createGroup-id', obj, idx);
      //       if (idx > -1) {
      //         // this.selectGroup(this.groupList[idx]) // commend by g
      //       }
      //     }, 1000);

      //   });
      // } else {
      //   this.groupData = '';
      //   // this.openPublishDialog('Group name already exists !!')
      //   //this.broadcastInfo({ src: 'coursePanel', event: 'openDialog', data: "Group name already exists !!" });
      //   this.groupWindow = true;
      // }
      var data = { "name": "", "groupList": [], "color": "" };
      data['name'] = this.groupName;
      data['color'] = this.groupStatusColor;
      this.createGroup(data)
    } else if (this.groupState == 'edit') {
      //let idx = this.groupList.findIndex(e => e._id == this.editGroupData['_id']);
      if (this.editGroupData && this.editGroupData['_id']) {
        this.editGroupData['name'] = this.groupName;
        this.editGroupData['color'] = this.groupStatusColor;
        console.log("edit", this.editGroupData['color']);
        this.updateGroup(this.editGroupData, (obj) => {
          // delete this.editGroupData;
        })
      }
      delete this.editGroupData;
    }
    this.groupWindow = false;
    delete this.groupName;
    delete this.groupState
    delete this.groupData;
    console.log(this.groupName, this.groupData, this.groupList);
  }
  createGroup(data) {
    this.configService.createGroup(data).then(data => {
      if (data) {
        console.log("groupCreated ", data);
        this.getGroupData();
        // cbck(data)
      }
    }).catch((error) => {
      // console.log("error ", error);
      // cbck(error)
    });
  }

  updateGroup(data, cbck) {
    console.log("update", data);
    if (data) {
      this.configService.updateGroup(data._id, data).then(data => {
        if (data) {
          console.log("group updated ", data);
          this.getGroupData();
          cbck(data)
        }
      }).catch((error) => {
        // console.log("error ", error);
        cbck(error)
      });
    }

  }
  deleteGroup(_id) {
    this.configService.deleteGroup(_id).then(data => {
      if (data) {
        console.log("group deleted ", data);
        this.getGroupData();
      }
    }).catch((error) => {
      // console.log("error ", error);
    });
  }

  getGroupData() {
    this.configService.getGroupListData().then((response: any) => {
      console.log("getGroupData ", response);
      this.groupList = response;
      //this.groupArray = response;
      //this.getGroupItem();
      this.broadcastInfo({ src: 'navyApp', event: 'updateGroup', data: this.groupList });
      // cbck(response)

    }).catch((error) => {
      console.log("error ", error);
    });
  }
  createTypeForm(data) {
    var min = -1;
    var max = 100;
    let tagShapeId = data.data[0]['tagShapeId'] ? data.data[0]['tagShapeId'] : this.getUIDData();
    let locationShape = data.data[0]['locationShape'] ? data.data[0]['locationShape'] : "sphere";
    this.pickedMeshObj = {
      "tagShapeId": tagShapeId,
      "shapeId": locationShape || "sphere",
      "position": data.data[0]['tagLocation'] || {
        "x": 0,//(Math.random() * (max - min + 1)) + min,
        "y": 0,//(Math.random() * (max - min + 1)) + min,
        "z": 0,//(Math.random() * (max - min + 1)) + min,
      },
      "parentMeshId": "element-envelope-59__CEROS_200_aft-9e5d5679-5eeb-41d7-83e0-46aa60be4b9b",
      "venue": this.tagServ.port,
      'description':data.data[0]['purpose'] || ""
    } 
    var props = {
      "Location": data.chit.Location,
      "Vessel": data.chit.Vessel,
      "activity": data.chit.activity,
      "tagLocation":data.data[0]['tagLocation'] || {
        "x": 0,//(Math.random() * (max - min + 1)) + min,
        "y": 0,//(Math.random() * (max - min + 1)) + min,
        "z": 0,//(Math.random() * (max - min + 1)) + min,
      },
      "officerName": data.chit.officerName,
    }
    this.saveNotesData['tagShapeId']= this.pickedMeshObj.tagShapeId ? this.pickedMeshObj.tagShapeId : '';
    this.saveNotesData['startDate'] = this.datePipe.transform(data.formdate,'MMM,dd,YYY, h:mm a')?this.datePipe.transform(data.formdate,'MMM,dd,YYY, h:mm a'): this.datePipe.transform(new Date(),'MMM,dd,YYY, h:mm a');
    this.saveNotesData['startTime'] = data.chit.startTime ? data.chit.startTime : '';
    this.saveNotesData['endTime'] = data.chit.endTime ? data.chit.endTime : '';
    this.saveNotesData['typeCollectionId'] = data.data[0].typeCollectionId;
    this.saveNotesData['typeCollection'] = data.data[0].typeCollection;
    this.saveNotesData['parentMeshId'] = data.chit.emitterLocation;
    this.saveNotesData['shapeId'] = this.selectedCollection && this.selectedCollection['type'] ? this.selectedCollection['type'] : '';
    this.saveNotesData['props'] = props;
    this.saveNotesData['formStatus'] = 'pending';
    this.saveNotesData['tagName'] = data.chit.activity;
    this.saveNotesData['description'] = data.data[0]['purpose'] || "";
    this.createNotes()
  }

  updateTagShapes(data){
    let tagShapeId = data.data[0]['tagShapeId'] ? data.data[0]['tagShapeId'] : this.getUIDData();
    let locationShape = data.data[0]['locationShape'] ? data.data[0]['locationShape'] : "sphere";
    this.pickedMeshObj = {
      "tagShapeId": tagShapeId,
      "locationShape": locationShape || "sphere",
      "position": data.data[0]['tagLocation'] || {
        "x": 0,//(Math.random() * (max - min + 1)) + min,
        "y": 0,//(Math.random() * (max - min + 1)) + min,
        "z": 0,//(Math.random() * (max - min + 1)) + min,
      },
      "parentMeshId": "element-envelope-59__CEROS_200_aft-9e5d5679-5eeb-41d7-83e0-46aa60be4b9b",
      "venue": this.tagServ.port,
    }
  }
  closeWarning() {
    delete this.turnoverCreateMsd;
  }

  chitsStatusClosed(){
    this.chitsApprove = false;
    delete this.chitsNotes;
    delete  this.chitData;
    delete this.selectUser;
    delete this.userEmailData;
    this.chitsStatus="inprogress";
  }

  chitsStatusCompleted(){
    console.log("chitsStatusCompleted",this.chitsStatus);
    this.updateDataNotification();
    // this.
  }
  chitsNotes:any;
  updateDataNotification() {
    this.chitsApprove = false;
    var data = {
      "chitId": this.chitData['typeCollectionId'],
      "email": this.selectUser.email,
      "status": this.chitsStatus=='true'?'yes':this.chitsStatus=='false'?'no':'inprogress',
    }
    // this.configService.getProjectConfig().then((project_config: any) => {
    this.chitService.updateNotification(data)
      .subscribe((res) => {
        // this.broadcastIframeInfo({ iframeConnection: 'sdms-connection', event: 'notification' });
        this.updateStatus();
        console.log("notification update", res)
      }, error => {
        console.error('There was an error!');
      })
    // })
  }

  updateStatus() {
    if (this.selectUser) {
      var currentUserIDX = this.userEmailData.findIndex(e=> e.email == this.selectUser.email);
      if(currentUserIDX>-1){
        this.userEmailData[currentUserIDX]['status']=this.chitsStatus=='true'?'yes':this.chitsStatus=='false'?'no':'inProgress';
        this.userEmailData[currentUserIDX]['message'] = this.chitsNotes?this.chitsNotes:'';
      }
       this.updateChit()
    }
  }
  userEmailData:any;
  updateChit() {
    if (this.chitData&&this.chitData['typeCollectionId']) {
      var emailId = JSON.stringify(this.userEmailData);
      var data = {
        status: (this.chitsStatus=='true'?'yes':this.chitsStatus=='false'?'no':'inprogress'),
        comments: (this.chitsNotes ? this.chitsNotes : ""),
        verified: (""),
        notification:(emailId.length > 0 ? emailId : [])
      }
      this.chitService.approveData(this.chitData['typeCollectionId'], data)
        .subscribe((res) => {
          var status = res.status
          var data = res['value'];
          console.log(res);
          if (data) {
            // this.chitsStatusClosed();
            // if(data['status'] == "completed"){
              // this.notesDataUpdate('completed');
              this.getChitStatusUpdate();
              // this.socketService.notificationUpdate('success');
            // }
            // if(data['status'] == "reject"){
              // this.notesDataUpdate(false);
              // this.getChitStatusUpdate();
              // this.socketService.notificationUpdate('success');
            // }
          }
        }, error => {
          console.error('There was an error!');
        })
    }
  }
getChitStatusUpdate(){
  this.chitService.getChitActivityData(this.chitData['typeCollectionId']).subscribe(
    (response) => {
      var repos = response;
      if(response.length>0){
        var notification = JSON.parse(response[0].notification);
        var reject = notification.filter(t => t.status == 'no'||t.status == 'reject');
        var inprogress = notification.filter(t => t.status.toLocaleLowerCase() == 'inprogress');
        let idx = this.selectNotesData.findIndex(e=> e['_id']==this.chitData['_id']);
        if(idx>-1){
          if (reject.length>0) {
            this.selectNotesData[idx]['formStatus'] = 'reject';
          }else if(inprogress.length>0){
            this.selectNotesData[idx]['formStatus'] = 'inProgress';
          }else{
            this.selectNotesData[idx]['formStatus'] = 'completed';
          }
          
          this.updateNotes(this.selectNotesData[idx]);
        }
        var logBookData = {
          'typeCollection': this.notesData.typeCollection, "tagName": this.notesData.tagName,
          "description": this.chitsNotes,
          "startDate": this.datePipe.transform(this.notesData.startDate, 'yyyy-MM-dd'), 
          "startTime": this.notesData.startTime,
           "endTime": this.notesData.endTime, 
          "formStatus": this.selectNotesData[idx]['formStatus']
        };
        this.chitService.createLogBook(logBookData);
        this.chitsStatusClosed();
      }
    
    })
}

  roundsStatusCompleted() {
    var data;
    data = {
      status: this.status,
      notes: this.roundsNotes,
    }
    this.chitService.approveRoundsData(this.roundsId, data)
      .subscribe((res) => {
        if (res['status'] === '200') {
        }
      }, error => {
        console.error('There was an error!');
      })
    if (this.status == 'true') {
      this.notesData['formStatus'] = 'completed',
        this.updateRoundsNotesData(this.notesData._id, this.notesData);
    }
    var logBookData = {'typeCollection': this.notesData.typeCollection,"tagName":this.notesData.tagName,"description": this.roundsNotes,
    "startDate":this.datePipe.transform(this.notesData.startDate, 'yyyy-MM-dd'),"startTime": this.notesData.startTime,"endTime": this.notesData.endTime,"formStatus":this.notesData['formStatus'] };
    this.chitService.createLogBook(logBookData);
    // if (this.status == false) {
    //   this.notesData['formStatus'] = data?data: 'reject',
    //   this.updateRoundsNotesData(this.notesData._id,this.notesData);
    // }
  }
  roundsStatusClosed() {
    this.roundsApprove = false;
    delete this.status;
    delete this.roundsNotes;
  }
  updateRoundsNotesData(id, data) {
    this.configService.updateNotes(id, data).then(data => {
      if (data) {
        console.log("notes updated ", data);
        this.roundsApprove = false;
        this.scheduleApprove = false;
        delete this.status;
        delete this.roundsNotes;
        this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
        // this.getLayoutData();
        // this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
      }
    }).catch((error) => {
      console.log("error ", error);
    });
  }
  // scheduleApprove
  scheduleStatusCompleted() {
    var data;
    data = {
      status: this.status,
      notes: this.roundsNotes,
    }
    this.chitService.approveScheduleData(this.roundsId, data)
      .subscribe((res) => {
        if (res['status'] === '200') {
        }
        // this.chitService.sendReload("reload");
        // this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: tagShape['ops'][0] });
      }, error => {
        console.error('There was an error!');
      })
    if (this.status == 'true') {
      this.notesData['formStatus'] = 'completed',
        this.updateRoundsNotesData(this.notesData._id, this.notesData);
    }
    var logBookData = {'typeCollection': this.notesData.typeCollection,"tagName":this.notesData.tagName,
    "description": this.roundsNotes,"startDate":this.datePipe.transform(this.notesData.startDate, 'yyyy-MM-dd'),"startTime": this.notesData.startTime,"endTime": this.notesData.endTime,"formStatus":this.notesData['formStatus'] };
    this.chitService.createLogBook(logBookData);
    // if (this.status == false) {
    //   this.notesData['formStatus'] = data?data: 'reject',
    //   this.updateRoundsNotesData(this.notesData._id,this.notesData);
    // }
  }
  scheduleStatusClosed() {
    this.scheduleApprove = false;
    delete this.status;
    delete this.roundsNotes;
  }

  createVisitorNotes() {
    if (this.visitorInformation.length > this.visitorIdx) {
      let visitor = this.visitorInformation[this.visitorIdx]
      this.pickedMeshObj = {
        "tagShapeId": visitor.tagShapeId || this.getUIDData(),
        "shapeId": "sphere",
        "position": {
          "x": 0,
          "y": 0,
          "z": 0,
        },
        "parentMeshId": "element-envelope-59__CEROS_200_aft-9e5d5679-5eeb-41d7-83e0-46aa60be4b9b",
        "venue": this.tagServ.port,
      }
      var props = {
        "Location": "",
        "Vessel": visitor.role,
        "activity": visitor['firstName'] + " " + visitor['lastName'],
      }
      this.saveNotesData['tagShapeId'] = this.pickedMeshObj.tagShapeId ? this.pickedMeshObj.tagShapeId : '';
      this.saveNotesData['startDate'] = this.datePipe.transform(this.chitCreateDate, 'MMM,dd,YYY, h:mm a');
      this.saveNotesData['startTime'] = this.chitFormvalue['Start'] || '';
      this.saveNotesData['endTime'] = this.chitFormvalue['End'] || '';;
      this.saveNotesData['typeCollectionId'] = visitor._id;
      this.saveNotesData['typeCollection'] = 'Visitor';
      this.saveNotesData['parentMeshId'] = '';
      this.saveNotesData['shapeId'] = 'Visitor';
      this.saveNotesData['props'] = props;
      this.saveNotesData['formStatus'] = 'pending';
      this.saveNotesData['tagName'] = this.chitFormvalue.activity;
      this.saveNotesData['description'] =  this.chitFormvalue['purpose'] || "";
      this.createNotesData();
      var logBookData = {
        'typeCollection': 'Visitor',
        "tagName":visitor['firstName'] + " " + visitor['lastName'],
        "description": this.chitFormvalue['purpose'] || "",
        "startDate": this.datePipe.transform(this.chitCreateDate, 'yyyy-MM-dd'),
        "startTime": this.chitFormvalue['Start'],
        "endTime": this.chitFormvalue['End'] 
      };
      this.chitService.createLogBook(logBookData);
    } else {
      this.visitorIdx = 0;
    }
  }

  createNotesData() {
    if (this.saveNotesData && Object.keys(this.saveNotesData).length != 0) {
      this.saveNotesData['layoutId'] = this.selectedTagItem['_id'];
      this.saveNotesData['markerId'] = this.markerId || ''
      this.configService.createNotes(this.saveNotesData).then(saveNotesData => {
        this.saveNotesData = saveNotesData['ops'][0]
        this.broadcastInfo({ src: 'navyApp', event: 'layoutNotesData', data: saveNotesData });
        this.createTagShape();
        if (this.chitService.typeCollectionForm && this.chitService.typeCollectionForm['name'] == "createCollectionFormActive" && this.chitService.typeCollectionForm['type'] == 'Turnover') {
          console.log("turnovercreate");
          this.broadcastInfo({ src: 'tagPoint', event: 'turnoverSheetCreate', data: saveNotesData });
          delete this.chitService.typeCollectionForm;
        }
        this.visitorIdx = this.visitorIdx + 1;
        this.createVisitorNotes();
      }).catch((error) => {
        console.log("error ", error);
      });
    }
  }

  openLocationForm(status){
    this.locationMaster = true;
    if(status == 'create'){
      this.locationFormGroup.reset();
      this.locationFormGroup.controls['location'].setValue('{"x":0,"y":0,"z":0}');
      this.locationForm = true;
      this.locationFormButton = true;
      this.updatelocationFormButton = false;
      this.viewFlexType = false;
      this.viewRoundsType = false
      this.viewVisitorType = false;
      this.viewlotoType = false;
      delete this.locationShape;
      delete this.locationShapeCollectionIcon;
      delete this.locationShapeCollectionImg;
    }else if(status == 'edit'){
      this.locationForm = true;
      this.locationFormButton = false;
      this.updatelocationFormButton = true;
    }
  }

  getlocationType(selectedValue) {
    console.log('getlocationType', selectedValue);
    if (selectedValue == 'rounds') {
      this.viewVisitorType = false;
      this.viewRoundsType = true;
      this.viewFlexType = false;
      this.viewlotoType = false;
    } else if (selectedValue == 'visitor') {
      this.viewRoundsType = false
      this.viewVisitorType = true;
      this.viewFlexType = false;
      this.viewlotoType = false;
    } else if (selectedValue == 'flex') {
      this.viewFlexType = true;
      this.viewRoundsType = false
      this.viewVisitorType = false;
      this.viewlotoType = false;
    }else if (selectedValue == 'loto') {
      this.viewFlexType = false;
      this.viewRoundsType = false
      this.viewVisitorType = false;
      this.viewlotoType = true;
    }
  }

  setLocationForm(locationDet) {
    delete this.locationShape;
    delete this.locationShapeCollectionIcon;
    delete this.locationShapeCollectionImg;
    Object.keys(locationDet).forEach(key => {
      if (key == 'type') {
        if (locationDet[key] == 'rounds') {
          this.viewVisitorType = false;
          this.viewRoundsType = true;
          this.viewFlexType = false;
          this.viewlotoType = false;
        } else if (locationDet[key] == 'visitor') {
          this.viewRoundsType = false
          this.viewVisitorType = true;
          this.viewFlexType = false;
          this.viewlotoType = false;
        } else if (locationDet[key] == 'flex') {
          this.viewFlexType = true;
          this.viewRoundsType = false
          this.viewVisitorType = false;
          this.viewlotoType = false;
        }else if (locationDet[key] == 'loto') {
          this.viewFlexType = false;
          this.viewRoundsType = false
          this.viewVisitorType = false;
          this.viewlotoType = true;
        }
      }
      if (key == 'location') {
        let location = JSON.stringify(locationDet[key]);
        this.locationFormGroup.controls[key].setValue(location);
      } else if (key == 'locationShape') {
        let shape = this.LocationShapeArray.find(el => el.id === locationDet[key]);
        if (shape) {
          this.locationShape = locationDet[key];
          this.locationShapeCollectionIcon = locationDet[key];
          this.locationShapeCollectionImg = shape.icon;
          this.locationFormGroup.controls[key].setValue(locationDet[key]);
        }
      } else {
        if(key != 'isChecked'){
          this.locationFormGroup.controls[key].setValue(locationDet[key])
        };       
      }
    });
  }
  
  createLocation() {
    if (this.locationFormGroup.status == 'VALID') {
      delete this.locationFormGroup.value['_id'];
      let locationkey;
      if (this.locationFormGroup.value['type'] == 'rounds') {
        locationkey = 'rounds_' + this.locationFormGroup.value['locationType'];
      } else if (this.locationFormGroup.value['type'] == 'visitor') {
        locationkey = 'visitor_' + this.locationFormGroup.value['locationType'];
      } else if (this.locationFormGroup.value['type'] == 'flex') {
        locationkey = 'flex_' + this.locationFormGroup.value['locationType'];
      } else if (this.locationFormGroup.value['type'] == 'loto') {
        locationkey = 'loto_' + this.locationFormGroup.value['locationType'];
      }
      let pushParams = {
        'locationKey': locationkey.split(" ").join(''),
        'location': JSON.parse(this.locationFormGroup.value['location']),
        'locationShape': this.locationFormGroup.value['locationShape'] || "sphere"
      }
      if (!this.chitService.locationList.some(group => group.locationKey == pushParams['locationKey'])) {
        this.showerror = false;
        let objData = Object.assign({}, this.locationFormGroup.value, pushParams);
        console.log('createLocation', objData);
        this.chitService.createLocation(objData).subscribe((res) => {
          console.log('location', res);
          this.locationMaster = false;
          this.broadcastInfo({ src: 'tagPoints', event: 'locationList', status: true });
          this.chitService.sendReload("reload");
        }, error => {
          console.error('There was an error!');
        });
      } else {
        this.locationType = this.locationFormGroup.value['locationType'];
        this.showerror = true;
      }
    }
  }
  
  updateLocation() {
    if (this.locationFormGroup.status == 'VALID') {
      let locationkey;
      if (this.locationFormGroup.value['type'] == 'rounds') {
        locationkey = 'rounds_' + this.locationFormGroup.value['locationType'];
      } else if (this.locationFormGroup.value['type'] == 'visitor') {
        locationkey = 'visitor_' + this.locationFormGroup.value['locationType'];
      } else if (this.locationFormGroup.value['type'] == 'flex') {
        locationkey = 'flex_' + this.locationFormGroup.value['locationType'];
      } else if (this.locationFormGroup.value['type'] == 'loto') {
        locationkey = 'loto_' + this.locationFormGroup.value['locationType'];
      }
      let pushParams = {
        'locationKey': locationkey.split(" ").join(''),
        'location': JSON.parse(this.locationFormGroup.value['location']),
        'locationShape': this.locationFormGroup.value['locationShape'] || "sphere"
      }
      // if (!this.chitService.locationList.some(group => group.locationKey == pushParams['locationKey'])) {
        let objData = Object.assign({}, this.locationFormGroup.value, pushParams);
        this.chitService.updateLocation(this.locationFormGroup.value['_id'], objData).subscribe((res) => {
          this.chitService.sendReload("reload");
          this.locationMaster = false;
          this.broadcastInfo({ src: 'tagPoints', event: 'locationList', status: true });
        }, error => {
          console.error('There was an error!');
        });
      // } else {
      //   this.locationType = this.locationFormGroup.value['locationType'];
      //   this.showerror = true;
      // }
    }
  }

  closeLocation(){
    this.locationMaster = false;
    this.showerror = false;
  }

  /* * *
   * add location
   * */
  pickLocation() {
    this.ctrlServ.isAddLocationState = true;
    this.ctrlServ.isActiveLayout = 'location'
    this.locationMaster = false;
  }

  /* * *
   * add picked location 
   * */
  addPickedLocation(obj) {
    this.locationMaster = true;
    this.ctrlServ.isAddLocationState = false;   
    let mname = obj['mname'] || '';
    let pos = typeof obj['position'] === "object" ? JSON.stringify(obj['position']) : JSON.stringify({ x: 0, y: 0, z: 0 });
    this.locationFormGroup.controls['meshId'].setValue(mname);
    this.locationFormGroup.controls['location'].setValue(pos);
  }
  
  openLocaionShape() {
    this.locationShapeModel = true;
    this.locationMaster = false;
  }

  locationShapeWindowClose() {
    this.locationShapeModel = false;
    this.locationMaster = true;
  }
  selectLocationShapeData() {
    this.locationShape = this.locationShapeCollectionIcon;
    this.locationShapeModel = false;
    this.locationMaster = true;
  }
  selectLocationShapeIcon(e) {
    this.locationShapeCollectionIcon = e.id;
    this.locationShapeCollectionImg = e.icon;
  }

  layoutLocationTagClose(tagData){
    console.log('tagData',tagData);
    this.tagServ.showHideLocationTag(tagData,true);
  }

  showVisitorNotificationPoup(Info){
    let visitorOOD = Info.visitorOOD || [];
    visitorOOD.forEach(visitor=>{
      if(visitor['email'] == this.configService.loginInfo['accountEmail']){
        this.broadcastInfo({ src: 'material', event: 'openVisitorNotification', data: Info });
      }
    })
  }

  
  /* * *
  * collection upload section
  * * */
  placeHolderUpdate(e) {
    setTimeout(() => {
      if (e == 'plan') {
        var jettyPlanElem = document.getElementById('jettyplan');
        jettyPlanElem.children[0].innerHTML = 'Drag Excel file or select file';
      }
    }, 50);
  }

  resetPlanFile() {
    console.log("resetPlanFile ");
    this.formData = new FormData();
    this.uploadFile.reset();
    this.uploadFile.setValue([]);
    this.placeHolderUpdate('plan');
    this.planUploadStatus = false;
    this.uploadPopupState = false;
  }

  clearFile(){
    this.formData = new FormData();
    this.uploadFile.reset();
    this.uploadFile.setValue([]);
    this.placeHolderUpdate('plan');
    this.planUploadStatus = false;
  }

  uploadPopupToggle() {
    this.uploadPopupState = false;
    this.broadcastInfo({ src: 'tagPoint', event: 'closeExcelUploadPopup' });
    this.resetPlanFile();
  }

  /* * *
  * excle file upload
  * * */
  formData = new FormData();
  onExcelValueChange(file: File[]) {
    if (file.length > 0) {
      console.log(file, "File changed!");
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file[0].name)[1];
      if (ext == 'xlsx' || ext == 'xls' || ext == 'xlsm' || ext == 'csv') {
        this.planUploadStatus = true;
        if (this.selectedLayoutCollection && this.logbookLayoutCollection) {
          this.formData.append('collectionLayoutID', this.selectedLayoutCollection['_id']);
          this.formData.append('typeCollection', this.selectedLayoutCollection['type']);
          this.formData.append('logbookLayoutID', this.logbookLayoutCollection['_id']),
          this.formData.append('port', this.selectedLayoutCollection['venue']);
          this.formData.append('file', file[0]);
          console.log("excelUploadSuccess ", this.selectedLayoutCollection);
        }
      } else {
        this.planUploadStatus = false;
        this.broadcastInfo({ src: 'ctrlserv', event: 'opendialogbox', data: 'Invalid File !!', key: '' });
        this.resetPlanFile();
      }
    } else {
      this.planUploadStatus = false;
      this.placeHolderUpdate('plan');
    }
  }

  /* * *
  * upload collection data
  * * */
  uploadCollectionData() {
    let url = this.configService.project_config.uploadCollection;
    this.configService.uploadExcelData(url, this.formData, this.excelUploadSuccess);
  }

  /* * * * *
  * check Json or Not
  * * * * * */
  hasJsonStructure(str) {
    if (typeof str !== 'string') return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]'
        || type === '[object Array]';
    } catch (err) {
      return false;
    }
  }

  /* * *
  * upload collection response
  * * */
  excelUploadSuccess = ((response) => {
    console.log("collection upload response : ", response);
    let isJson = this.hasJsonStructure(response);
    if (response && isJson) {
      response = JSON.parse(response);
      if (response['result'] === 'success') {
        this.broadcastInfo({ src: 'ctrlserv', event: 'opendialogbox', data: response['message'], key: '' });
        this.broadcastInfo({ src: 'tagPoints', event: 'getCollectionData', data: '' });
        this.broadcastInfo({ src: 'tagPoint', event: 'closeExcelUploadPopup' });
        this.resetPlanFile();
      } else {
        this.broadcastInfo({ src: 'ctrlserv', event: 'opendialogbox', data: response['message'], key: '' });
        // this.broadcastInfo({ src: 'tagPoint', event: 'closeExcelUploadPopup' });
        this.clearFile();
      }
    } else {
      this.clearFile();
    }
  });

  //  notification 

  notificationChatWindow: boolean = false;
  tagPointMsg: any;
  notificationData: string = "";
  notificationTagShapeId: any;
  msgTagShow: boolean = false;
  notificationTag(e) {
    if (this.notificationTagShapeId == e.tagShapeId && this.notificationChatWindow) {
      this.notificationChatWindow = false;
    }else{
      this.notificationData = "";
      this.notificationTagShapeId = e.tagShapeId
      this.notificationChatWindow = true;
      this.tagPointMsg = e;
    }

    // this.msgTagShow = true;
  }
  notificationCloseWindow() {
    delete this.tagPointMsg;
    delete this.notificationData;
    this.notificationChatWindow = false;
  }
  notificationSend() {
    if (this.validation()) {
      this.isNotValid = false;
      this.notificationData
      this.tagPointMsg;
      if (this.tagPointMsg.typeCollection == "Rounds") {
        this.getRoundsActivityDataView(this.tagPointMsg.typeCollectionId, (notifyUserList) => {
          if (notifyUserList.length > 0) {
            this.userdataSocket(notifyUserList);
            this.notificationCloseWindow();
          }
        });
      }else if (this.tagPointMsg.typeCollection == "Flex") {
        this.getFlexActivityDataView(this.tagPointMsg.typeCollectionId, (notifyUserList) => {
          if (notifyUserList.length > 0) {
            this.userdataSocket(notifyUserList);
            this.notificationCloseWindow();
          }
        });
      }else if (this.tagPointMsg.typeCollection == "Lockout/Tagout") {
        this.getLockoutActivityDataView(this.tagPointMsg.typeCollectionId, (notifyUserList) => {
          if (notifyUserList.length > 0) {
            this.userdataSocket(notifyUserList);
            this.notificationCloseWindow();
          }
        });
      }else if (this.tagPointMsg.typeCollection == "Teams") {
        this.getTeamsActivityDataView(this.tagPointMsg.typeCollectionId, (notifyUserList) => {
          if (notifyUserList.length > 0) {
            this.userdataSocket(notifyUserList);
            this.notificationCloseWindow();
          }
        });
      }
    } else {
      this.isNotValid = true;
    }
  }

  // validation
  notificationError: string = "";
  isNotValid: boolean = false;
  userInfoDetails: any = [];
  validation() {
    this.resetError();
    this.notificationError = (this.notificationData == "") ? "Input Required" : "";
    return this.notificationData != "";
  }

  resetError() {
    this.notificationError = "";
  }

  onValidation() {
    if (this.isNotValid) {
      this.validation();
    }
  }

  userdataSocket(userListData = []) {      
    var turnoverSheet = true;
    var obj = {
      "userList": userListData,
      "tagShapeId": this.tagPointMsg.tagShapeId,
      "layoutId": this.tagPointMsg.layoutId,
      "markerId": this.tagPointMsg.markerId ? this.tagPointMsg.markerId : " ",
      "AppName": "sdms",
      "eventName": "chat-communication",
      'turnoverSheet': turnoverSheet,
      "messageData": this.getMessageData(),
      "CommentScreendata": this.tagPointMsg ? this.tagPointMsg : " ",
      'shipDetails': this.tagPointMsg,
      "purpose": "purpose",
      'loginUser': this.userInfoDetails[0],
      "chitId": this.tagPointMsg.typeCollectionId,
      "type": this.tagPointMsg.typeCollection,
      "notification": this.notificationData,
      "senderName": this.userInfoDetails[0].fName + ' ' + this.userInfoDetails[0].role || "",
      "senderEmail": this.userInfoDetails[0].email || "",
    }
    console.log("obj", obj);
    this.socketService.sendUserDetailsInfo(obj);   
  }

  getMessageData() {
    var loginUserdata = {};
    var userData = this.usersDetails.filter(obj => obj.email == this.configService.accountEmail)
    if (userData.length > 0) {
      loginUserdata = {
        "fName": userData[0]['First Name'],
        "lName": userData[0]['Last Name'],
        "role": userData[0]['Role'],
        "email": userData[0].email
      }
      // this.userList.push(this.loginUserdata);
    }
    
    this.userInfoDetails.push(loginUserdata)
    var messageData = {
      "notesId": '',
      "layoutId": this.tagPointMsg.layoutId,
      "Messagedate": new Date(),
      "email": this.userInfoDetails[0].email || "",
      "name": this.userInfoDetails[0].fName + '' + this.userInfoDetails[0].lName || "",
      "comments": "Notification send for" + '@' + this.configService.accountEmail,
    }
    return messageData
  }
  
  getRoundsActivityDataView(roundsId, cbck) {
    let userListNotification = [];
    this.chitService.getRoundsData(roundsId).subscribe((response) => {
      if (response.length > 0) {
        let roundsData = response[0];
        var userData = this.usersDetails.filter(obj => obj.email == this.configService.accountEmail)
        let roundsFilterData = response.filter(obj => obj.email == roundsData.email);
        if (roundsFilterData.length > 0) {
          roundsFilterData.forEach(rounds => {
            var userDetail = this.usersDetails.find(element => element.email == rounds.email);
            if (userDetail) {
              var notifyUsers = {
                "firstName": userDetail['First Name'] ? userDetail['First Name'] : '',
                "lastName": userDetail['Last Name'] ? userDetail['Last Name'] : '',
                "email": userDetail.email,
                'status': 'inProgress',
                'role': rounds.role ? rounds.role : '',
                'viewStatus': false,
                "senderEmail": userData[0].email,
              }
            }
            userListNotification.push(notifyUsers);
          });
          cbck(userListNotification);
        }
      }
    },
      error => {
        console.log('Request failed with error');
        cbck([]);
      })
  }

  getFlexActivityDataView(flexId, cbck) {
    let userListNotification = [];
    this.chitService.getScheduleDatas(flexId).subscribe(
      (response) => {
        if (response.length > 0) {
          let flexData = response[0];
          var userData = this.usersDetails.filter(obj => obj.email == this.configService.accountEmail)
          let flexFilterData = response.filter(obj => obj.email == flexData.email);
          if (flexFilterData.length > 0) {
            flexFilterData.forEach(flex => {
              var userDetail = this.usersDetails.find(element => element.email == flex.email);
              if (userDetail) {
                var notifyUsers = {
                  "firstName": userDetail['First Name'] ? userDetail['First Name'] : '',
                  "lastName": userDetail['Last Name'] ? userDetail['Last Name'] : '',
                  "email": userDetail.email,
                  'status': 'inProgress',
                  'role': flex.role ? flex.role : '',
                  'viewStatus': false,
                  "senderEmail": userData[0].email,
                }
              }
              userListNotification.push(notifyUsers);
            });
            cbck(userListNotification);
          }
        }
      },
      error => {
        console.error('Request failed with error')
      })
  }

  getLockoutActivityDataView(lockoutId, cbck) {
    let userListNotification = [];
    this.tagoutServ.getTagoutDataList(lockoutId).subscribe(
      (response) => {
        if (response.length > 0) {
          let lockoutData = response[0];
          var userData = this.usersDetails.filter(obj => obj.email == this.configService.accountEmail)
          let lockoutFilterData = response.filter(obj => obj.name == lockoutData.name);
          if (lockoutFilterData.length > 0) {
            lockoutFilterData.forEach(lockout => {
              var userDetail = this.usersDetails.find(element => element.email == lockout.name);
              if (userDetail) {
                var notifyUsers = {
                  "firstName": userDetail['First Name'] ? userDetail['First Name'] : '',
                  "lastName": userDetail['Last Name'] ? userDetail['Last Name'] : '',
                  "email": userDetail.email,
                  'status': 'inProgress',
                  'role': lockout.role ? lockout.role : '',
                  'viewStatus': false,
                  "senderEmail": userData[0].email,
                }
              }
              userListNotification.push(notifyUsers);
            });
            cbck(userListNotification);
          }
        }
      },
      error => {
        console.error('Request failed with error')
      })
  }
  getTeamsActivityDataView(teamsId, cbck) {
    let userListNotification = [];
    this.chitService.getTeamsData(teamsId).subscribe(
      (response) => {
        if (response.length > 0) {
          let teamsData = response[0];
          var userData = this.usersDetails.filter(obj => obj.email == this.configService.accountEmail)
          let teamsFilterData = response.filter(obj => obj.email == teamsData.email);
          if (teamsFilterData.length > 0) {
            teamsFilterData.forEach(teams => {
              var userDetail = this.usersDetails.find(element => element.email == teams.email);
              if (userDetail) {
                var notifyUsers = {
                  "firstName": userDetail['First Name'] ? userDetail['First Name'] : '',
                  "lastName": userDetail['Last Name'] ? userDetail['Last Name'] : '',
                  "email": userDetail.email,
                  'status': 'inProgress',
                  'role': teams.role ? teams.role : '',
                  'viewStatus': false,
                  "senderEmail": userData[0].email,
                }
              }
              userListNotification.push(notifyUsers);
            });
            cbck(userListNotification);
          }
        }
      },
      error => {
        console.error('Request failed with error')
      })
  }
  /***
   * method for show chat notification
   */  
  showChatNotification(obj) {
    this.tagServ.showChatNotification(obj)
  }

  /***
   * method for hide chat notification
   */  
  closeChatNotification(obj){
    this.tagServ.hideChatNotification(obj);
  }

}
