import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { DrawService } from '../services/draw.service';
@Component({
  selector: 'app-marker',
  templateUrl: './marker.component.html',
  styleUrls: ['./marker.component.scss']
})
export class MarkerComponent implements OnInit {
  markerShape = 'free'
  fillStatus: boolean = false;
  markerWidth = 20;
  markerColor = "#ff0000";
  constructor(public drawService : DrawService ,public communicationServ : CommunicationService) { }

  ngOnInit(): void {
  }
  /* * * * *
  *  Cut And Draw feature
  * * * * * */
  onDrawShapeChange(ev) {
    this.markerShape = ev.value;
    this.broadcastInfo({ src: 'sidepanel', event: 'apply-marker-shape', key: '', data: this.markerShape });
  }
/* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
broadcastInfo(data: any) {
  this.communicationServ.getInstance().next(data);
}
  onDrawFill(ev) {
    if (ev.value == 'noFill') {
      this.fillStatus = true;
      this.broadcastInfo({ src: 'sidepanel', event: 'draw-mode-fill', key: this.fillStatus, data: '' });
    } else if (ev.value == 'fill') {
      {
        this.fillStatus = false;
        this.broadcastInfo({ src: 'sidepanel', event: 'draw-mode-fill', key: this.fillStatus, data: '' });
      }
    }
  }
  removeMarker() {
    this.broadcastInfo({ src: 'sidepanel', event: 'remove-marker', key: '', data: '' });
  }
  removeCut() {
    this.broadcastInfo({ src: 'sidepanel', event: 'remove-cut', key: '', data: '' });
  }
  clearMarkers() {
    this.broadcastInfo({ src: 'sidepanel', event: 'clear-markers', key: '', data: '' });
  }
  clearCuts() {
    this.broadcastInfo({ src: 'sidepanel', event: 'clear-cuts', key: '', data: '' });
  }
  applyCut() {
    this.broadcastInfo({ src: 'sidepanel', event: 'apply-cut', key: '', data: '' });
  }
  //
  markerColorChange(col) {
    this.broadcastInfo({ src: 'sidepanel', event: 'apply-marker-color', key: '', data: col });
  }
  markerWidthChange(ev) {
    this.broadcastInfo({ src: 'sidepanel', event: 'apply-marker-width', key: '', data: this.markerWidth });
  }

  undoMarker(){
    this.broadcastInfo({ src: 'sidepanel', event: 'undo-marker', key: '', data: '' });
  }
  undoCut(){
    this.broadcastInfo({ src: 'sidepanel', event: 'undo-marker', key: '', data: '' });
  }
  roundOffMarker(){
    this.broadcastInfo({ src: 'sidepanel', event: 'marker-roundoff', key: '', data: '' });
  }
  clearInMarker() {
    if (this.drawService.draw_select_mode) {
      this.clearMarkers()
    }
    if (this.drawService.cut_mode) {
      this.clearCuts()
    }
  }
  /**
 * Draw mode controls
 */
  removeInMarker() {
    if (this.drawService.draw_select_mode) {
      this.removeMarker()
    }
    if (this.drawService.cut_mode) {
      this.removeCut()
    }
  }

  undoInMarker(){
    if (this.drawService.draw_select_mode) {
      this.undoMarker()
    }
    if (this.drawService.cut_mode) {
      this.undoCut()
    }
  }
}
